export const Footer = () => {
  return (
    <>
      <footer id="footer">
        <div class="footer-top">
          <div class="container">
            <div class="row">
              <div class="col-lg-4 col-md-6">
                <div class="footer-info">
                  <h3>Pc 7</h3>
                  <p class="pb-3">
                    <em>
                    Pc 7 is an emerging online gaming platform in
                      World   that brings various enthralling online games in
                      Android and iOS mobile platforms for sports & game lovers.
                      Join our fantasy gaming platform, play your favorite game,
                      and win exciting rewards and cash prizes.
                    </em>
                  </p>
                </div>
              </div>

              <div class="col-lg-2 col-md-6 footer-links">
                <h4>Quick Links</h4>
                <ul> 
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="/about">About us</a>
                  </li>
                  {/* <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="/coming_soon">Terms of service</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="/coming_soon">Privacy policy</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="/coming_soon"> Cancellation/Refund Policy </a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="/coming_soon"> Legalities   </a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="/coming_soon"> Responsible Gaming    </a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="/coming_soon"> Frequently Asked Questions   </a>
                  </li> 
                </ul>
              </div>

              <div class="col-lg-2 col-md-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="/coming_soon">Fair Play</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="/coming_soon"> Fantasy Point System </a>
                  </li> */}
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="/howToPlay">How it  Works</a>
                  </li>
                  {/* <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="/coming_soon">Contact us</a>
                  </li> */}
                </ul>
              </div>

              <div class="col-lg-4 col-md-6 footer-newsletter">
                <h4>Payment Partners</h4>

                <div class="payments-partner mb-4">
                  <img src="img/payment_partner.png" alt="" class="img-fluid" />{" "}
                </div> 
                <p>  
                  <strong>Phone: </strong> +91 123-456-7890
                </p> 

                <p> 

                  <strong>Address: </strong> Noida, Uttar Pradesh 
                </p> 

                <p>
                  <strong>Email: </strong> support@Pc7.com
                </p>

                <div class="social-links mt-3"> 
                  <a target="_blank" href="https://www.instagram.com/Pc7_/?igshid=MzRlODBiNWFlZA%3D%3D" class="instagram">
                    <i class="bx bxl-instagram"></i>
                  </a>
                  <a target="_blank" href="https://www.youtube.com/@Pc7" class="youtube">
                    <i class="bx bxl-skype"></i>
                  </a>
                  <a target="_blank" href="https://t.me/Pc7fantasycricketprediction" class="linkedin">
                    <i class="bx bxl-telegram"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="copyright">
            Copyright &copy;{" "}
            <strong>
              <span>
                <a href="https://pc7.world/">www.pc7.world</a> Powerd by  <a href="https://digi7.io/"> DIGI7</a> 
                </span>
            </strong>
          </div>
        </div>
      </footer>
    </>
  );
};
