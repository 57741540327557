import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";

export const FairPlay = () => {
  return (
    <>
      {/* <!-- ======= Header ======= --> */}
      <header id="header" class="d-flex align-items-center header-transparent ">
        <div class="container d-flex align-items-start justify-content-between">
          <div class="logo">
            {/* <!-- <h1><a href="index.html"><span>Bootslander</span></a></h1> -->
        <!-- Uncomment below if you prefer to use an image logo --> */}
            <Link to="/">
              <img src="img/logo.png" alt="" class="img-fluid" />
            </Link>
          </div>

          {/* <nav id="navbar" class="navbar">
            <ul>
              <li>
                <a class="nav-link btn btn-primary scrollto" href="https://backend.Pc7.in/downloads">
                  {" "}
                  <i class="ri-download-fill me-2"></i> Download App
                </a>
              </li>
            </ul>
          </nav> */}

          {/* <!-- .navbar --> */}
        </div>
      </header>
      {/* <!-- End Header --> */}

      <section class="inner_hero_sec">
        <div class="heading">
          <h1>Fair Play Policy  </h1>
        </div>
      </section>

      <main id="main">
        <section class="breadcrumbs">
          <div class="container">
            <div class="d-flex justify-content-between align-items-center">
              <ol>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Fair Play Policy</li>
              </ol>
            </div>
          </div>
        </section>
        {/* <!-- End Breadcrumbs Section --> */}

        <section class="inner-page">
          <div class="container">
            <div class="content">
              <div class="TermsCondtns">
                  <div class="TermsCondtnsBox">

                  <div class="termsContent ftFx13 terms_condition_list">
                      <h4> Fair Play Policy </h4>
                      <div class="t_c_content mb-4 mb-md-5  ">
                        <p class="pdLt20"> 
                        This fair play policy (“Policy”), which forms an integral part of the terms and conditions that govern the use of Pc 7 (“Platform”), is intended to ensure that all Games played on the Platform adhere to high standards of fairness, competition, skill, and sportsmanship. Accordingly, the Policy sets out mandatory standards of conduct that is expected from users of the Platform (“Users” or “You”) and discloses, without limitation, specific measures implemented to ensure the aforesaid standards on the Platform.

                        </p>
                        
                        <p class="pdLt20"> 
                        We at Pc 7, value our users/Crypto, and hence encourage them to play responsibly. Pc 7 takes immense pride in providing a responsible and enjoyable experience to its users/Crypto; by taking preservative measures which keep our users/Crypto away from disagreeable situations that may arise while playing the ‘game of skill’. Our committed Basket  monitors users’ activities to alert them if we find any signs of addictive/compulsive behaviors.

                         </p>
                      </div>


                      <h4>
                      With an eye towards responsible play, we have established certain preventive measures:
                      </h4>
                      <div class="t_c_content  mt-3   mb-4 mb-md-5 "> 

                        <p>
                        We don’t authorise users below 18 years of age to register & play on Pc 7.

                        </p>
                        <p>
                        We have a super-mobile surveillance procedure for games on our platform to detect any possible fraudulent activities.

                        </p>
                        <p>
                        We have an active anti-collusion environment on our platform to maintain fair play.

                        </p>
                        <p> 
                        We employ remarkable encryption techniques to prevent security features and protect our users’ details.

                        </p>

                      </div>



                      <h4>
                      A Guide to Responsible Play
                      </h4>
                      <div class="t_c_content  mt-3  mb-4 mb-md-5 "> 
                      
                        <p>
                        Occasionally, Crypto may find it difficult to recognize that their online play is getting uncontrollable. A usual reaction is to cut down, hide or deny such problems and the harm it is causing. Some people will go as far as lying to themselves about how much money or time is being spent on online playing.

                           </p>

                      </div>


                      <h4>
                      Following are a few of the best practices to assist you play responsibly:
                      </h4>
                      <div class="t_c_content mt-3 "> 
                      
                      <p> Play in fairness and only for entertainment. </p>
                      <p> Do not play with the intent to make money or a solution to your financial problems. </p>
                      <p>  Never run after your losses during playing. </p>
                      <p>  Put aside an entertainment budget. </p>
                      <p>  Keep tabs on the time and the amount of money you’ve spent. </p>
                      <p>  Balance the time you spend on playing fantasy sports with other recreational activities. </p>


                      </div>

                     
                      


                  </div>
                  
                  </div>
                </div>
            </div>
          </div>
        </section>
      </main>
      {/* <!-- End #main --> */}

      {/* <!-- ======= Footer ======= --> */}
      <Footer />
      {/* <!-- End Footer --> */}

      <a
        href="#"
        class="back-to-top d-flex align-items-center justify-content-center"
      >
        <i class="bi bi-arrow-up-short"></i>
      </a>
      {/* <div id="preloader"></div> */}
    </>
  );
};
