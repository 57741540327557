import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

 const About = () => {
  return (
    <>
      {/* header */}
      <header id="header" class="d-flex align-items-center header-transparent">
        <div class="container d-flex align-items-start justify-content-between">
          <div class="logo">

            <Link to="/">
              <img src="img/logo.png" alt="" class="img-fluid" />{" "}
            </Link>
          </div>

          {/* <nav id="navbar" class="navbar">
            <ul>
              <li>
                <a class="nav-link btn btn-primary scrollto" href="https://backend.Pc7.in/downloads">
                  {" "}
                  <i class="ri-download-fill me-2"></i> Download App
                </a>
              </li>
            </ul>
          </nav> */}

          {/* <!-- .navbar --> */}
        </div>
      </header>
      {/* <!-- End Header --> */}

      <section class="inner_hero_sec">
        <div class="heading">
          <h1>About Us</h1>
        </div>
      </section>

      <main id="main">
        <section class="breadcrumbs">
          <div class="container">
            <div class="d-flex justify-content-between align-items-center">
              <ol>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>About Us</li>
              </ol>
            </div>
          </div>
        </section>
        {/* <!-- End Breadcrumbs Section --> */}

        <section class="inner-page">
          <div class="container">
            <div class="content">
              <div>
                <h3 className="mb-4" >Welcome to "Pc7" </h3>
                <p class="text-justify">
                  <strong  > At Pc7,</strong>
                  where fantasy meets crypto trading! Dive into the world of virtual trading, where you have the power to predict market trends and create your own winning portfolio. PC7 is not just a game; it's an immersive experience that combines the excitement of fantasy sports with the thrill of cryptocurrency trading.

                </p>
                {/* <br />
                <h3>Contact us</h3>
                <p class="text-justify">
                  Have questions, suggestions, or just want to share your epic victories? Reach out to us at <a className="text-decoration-underline" href="mailto:support@Pc7.com" >support@Pc7.com</a>. Your feedback fuels our quest to make Pc7 an unforgettable gaming experience.
                </p> */}
                <p>
                <br/>
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />

      {/* <div id="preloader"></div> */}
    </>
  );
};

export default About
