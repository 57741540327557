// import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";

export const TermsNConditionsMobile = () => {
  return (
    <>
      {/* <!-- ======= Header ======= --> */}
      {/* <header id="header" class="d-flex align-items-center header-transparent ">
        <div class="container d-flex align-items-start justify-content-between">
          <div class="logo"> 
            <Link to="/">
              <img src="img/logo.png" alt="" class="img-fluid" />
            </Link>
          </div>

          <nav id="navbar" class="navbar">
            <ul>
              <li>
                <a class="nav-link btn btn-primary scrollto" href="https://backend.Pc7.in/downloads">
                  {" "}
                  <i class="ri-download-fill me-2"></i> Download App
                </a>
              </li>
            </ul>
          </nav> 
        </div>
      </header> */}
      {/* <!-- End Header --> */}

      <section class="inner_hero_sec mt-0">
        <div class="heading">
          <h1>Terms and Conditions</h1>
        </div>
      </section>

      <main id="main">
        {/* <section class="breadcrumbs">
          <div class="container">
            <div class="d-flex justify-content-between align-items-center">
              <ol>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Terms and Conditions</li>
              </ol>
            </div>
          </div>
        </section> */}
        {/* <!-- End Breadcrumbs Section --> */}

        <section class="inner-page">
          <div class="container">
            <div class="content">
              <div class="TermsCondtns">
                <div class="TermsCondtnsBox">
                  {/* <h5>Last Updated: 22 June, 2023</h5> */}
                  <ol class="termsContent ftFx13 terms_condition_list">
                    <li>
                      <h3 id="Pc 7"> Pc 7</h3>
                      <div class="t_c_content">
                        <div class="pdLt20">

                        Pc 7 is the flagship brand of Sandhya Sports Media Private Limited ("Pc 7"). Through Pc 7, along with its sub-pages, and the Pc 7 App, Pc 7 operates five separate portals through which it offers cricket based, football based, basketball based, volleyball based, hockey based, baseball based, handball based, American football based and kabaddi based online fantasy games. Pc 7 as used herein shall be construed as a collective reference to Pc 7 and the Pc 7 App.

                             </div>
                      </div>
                    </li>

                    <li>
                      <h3 id="usage">	Usage of Pc 7</h3>
                      <div class="t_c_content">
                        <ul>
                          <li class="pdBtm10">
                            Any person <strong>("User")</strong> accessing Pc 7 or the Pc 7 App ('Pc 7 platform') for participating in the various contests and games (including fantasy games), available on Pc 7 platform (<strong>"Contest(s)"</strong>) ('Pc 7 Services') shall be bound by these Terms and Conditions, and all other rules, regulations and terms of use referred to herein or provided by Pc 7 in relation to any Pc 7 Services.
                          </li>
                          <li class="pdBtm10">
                            Pc 7 shall be entitled to modify these Terms and Conditions, rules, regulations and terms of use referred to herein or provided by Pc 7 in relation to any Pc 7 Services, at any time, by posting the same on Pc 7. Use of Pc 7 constitutes the User's acceptance of such Terms and Conditions, rules, regulations and terms of use referred to herein or provided by Pc 7 in relation to any Pc 7 Services, as may be amended from time to time. Pc 7 may, at its sole discretion, also notify the User of any change or modification in these Terms and Conditions, rules, regulations and terms of use referred to herein or provided by Pc 7, by way of sending an email to the User's registered email address or posting notifications in the User accounts. The User may then exercise the options provided in such an email or notification to indicate non-acceptance of the modified Terms and Conditions, rules, regulations and terms of use referred to herein or provided by Pc 7. If such options are not exercised by the User within the time frame prescribed in the email or notification, the User will be deemed to have accepted the modified Terms and Conditions, rules, regulations and terms of use referred to herein or provided by Pc 7.
                          </li>

                          <li class="pdBtm10">
                            Pc 7 may, at its sole and absolute discretion:
                            <ul class="pdTop10">
                              <li class="pdBtm10">
                                Restrict, suspend, or terminate any User's access to all or any part
                              </li>
                              <li class="pdBtm10">
                                Change, suspend, or discontinue all or any part of the Pc 7 Platform Services;
                              </li>
                              <li class="pdBtm10">
                                Reject, move, or remove any material that may be submitted by a User;
                              </li>
                              <li class="pdBtm10">
                                Move or remove any content that is available on Pc 7 Platform;
                              </li>
                              <li class="pdBtm10">
                                Establish general practices and limits concerning use of Pc 7 Platform;
                              </li>
                              <li class="pdBtm10">
                                Offer discounts to its users in form it deems fit <strong>("Cash Bonus")</strong>. All such discounts shall be credited in a separate account called as Cash Bonus Account.
                              </li>
                              <li class="pdBtm10">
                                Revise or make additions and/or deletions to the roster of Crypto available for selection in a Contest on account of revisions to the roster of Crypto involved in the relevant Sports Event;
                              </li>
                              <li class="pdBtm10">
                                Assign its rights and liabilities to all User accounts hereunder to any entity (post such assignment intimation of such assignment shall be sent to all Users to their registered email ids)
                              </li>
                            </ul>
                          </li>
                          <li class="pdBtm10">
                            In the event any User breaches, or Pc 7 reasonably believes that such User has breached these Terms and Conditions, or has illegally or improperly used Pc 7 or the Pc 7 Services, Pc 7 may, at its sole and absolute discretion, and without any notice to the User, restrict, suspend or terminate such User's access to all or any part of Pc 7 Contests or the Pc 7 Platform, deactivate or delete the User's account and all related information on the account, delete any content posted by the User on Pc 7 and further, take technical and legal steps as it deems necessary.
                          </li>
                          <li class="pdBtm10">
                            If Pc 7 charges its Users a platform fee in respect of any Pc 7 Services, Pc 7 shall, without delay, repay such platform fee in the event of suspension or removal of the User's account or Pc 7 Services on account of any negligence or deficiency on the part of Pc 7, but not if such suspension or removal is effected due to:
                            <ul class="pdTop10">
                              <li class="pdBtm10">
                                any breach or inadequate performance by the User of any of these Terms and Conditions; or
                              </li>
                              <li class="pdBtm10">
                                any circumstances beyond the reasonable control of Pc 7.
                              </li>
                            </ul>
                          </li>
                          <li class="pdBtm10">
                            Users consent to receiving communications such as announcements, administrative messages and advertisements from Pc 7 or any of its partners, licensors or associates.
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li>
                      <h3 id="intellectual_property">Intellectual Property</h3>
                      <div class="t_c_content">
                        <ul>
                          <li class="pdBtm10">
                            Pc 7 includes a combination of content created by Pc 7, its partners, affiliates, licensors, associates and/or Users. The intellectual property rights ("<strong>Intellectual Property Rights</strong>") in all software underlying Pc 7 and the Pc 7 Platform and material published on Pc 7, including (but not limited to) games, Contests, software, advertisements, written content, photographs, graphics, images, illustrations, marks, logos, audio or video clippings and Flash animation, is owned by Pc 7, its partners, licensors and/or associates. Users may not modify, publish, transmit, participate in the transfer or sale of, reproduce, create derivative works of, distribute, publicly perform, publicly display, or in any way exploit any of the materials or content on Pc 7 either in whole or in part without express written license from Pc 7.
                          </li>
                          <li class="pdBtm10">
                            Users may request permission to use any Pc 7 content by writing in to Pc 7 Helpdesk.
                            <a href="https://Pc7.in/termsNConditions" rel="nofollow">Pc 7 Helpdesk</a>

                          </li>
                          <li class="pdBtm10">
                            Users are solely responsible for all materials (whether publicly posted or privately transmitted) that they upload, post, e-mail, transmit, or otherwise make available on Pc 7 ("<strong>Users' Content</strong>"). Each User represents and warrants that he/she owns all Intellectual Property Rights in the User's Content and that no part of the User's Content infringes any third party rights. Users further confirm and undertake to not display or use of the names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary rights of any third party on Pc 7. Users agree to indemnify and hold harmless Pc 7, its directors, employees, affiliates and assigns against all costs, damages, loss and harm including towards litigation costs and counsel fees, in respect of any third party claims that may be initiated including for infringement of Intellectual Property Rights arising out of such display or use of the names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary rights on Pc 7, by such User or through the User's commissions or omissions
                          </li>
                          <li class="pdBtm10">
                            Users hereby grant to Pc 7 and its affiliates, partners, licensors and associates a World   wide, irrevocable, royalty-free, non-exclusive, sub-licensable license to use, reproduce, create derivative works of, distribute, publicly perform, publicly display, transfer, transmit, and/or publish Users' Content for any of the following purposes:
                            <ul class="pdTop10">
                              <li class="pdBtm10">
                                displaying Users' Content on Pc 7
                              </li>
                              <li class="pdBtm10">
                                distributing Users' Content, either electronically or via other media, to other Users seeking to download or otherwise acquire it, and/or
                              </li>
                              <li class="pdBtm10">
                                storing Users' Content in a remote database
                                accessible by end users, for a charge.
                              </li>
                              <li class="pdBtm10">
                                storing Users' Content in a remote database accessible by end users, for a charge.
                              </li>
                              <li class="pdBtm10">
                                This license shall apply to the distribution and the storage of Users' Content in any form, medium, or technology.
                              </li>
                            </ul>
                          </li>
                          <li class="pdBtm10">
                            All names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary rights on Pc 7(s) belonging to any person (including User), entity or third party are recognized as proprietary to the respective owners and any claims, controversy or issues against these names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary rights must be directly addressed to the respective parties under notice to Pc 7.
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li>
                      <h3 id="third_party_sites">
                        Third Party Sites, Services and Products
                      </h3>
                      <div class="t_c_content">
                        <ul>
                          <li class="pdBtm10">
                            Pc 7 may contain links to other Internet sites owned and operated by third parties. Users' use of each of those sites is subject to the conditions, if any, posted by the sites. Pc 7 does not exercise control over any Internet sites apart from Pc 7and cannot be held responsible for any content residing in any third-party Internet site. Pc 7's inclusion of third-party content or links to third-party Internet sites is not an endorsement by Pc 7 of such third-party Internet site.
                          </li>
                          <li class="pdBtm10">
                            Users' correspondence, transactions/offers or related activities with third parties, including payment providers and verification service providers, are solely between the User and that third party. Users' correspondence, transactions and usage of the services/offers of such third party shall be subject to the terms and conditions, policies and other service terms adopted/implemented by such third party, and the User shall be solely responsible for reviewing the same prior to transacting or availing of the services/offers of such third party. User agrees that Pc 7 will not be responsible or liable for any loss or damage of any sort incurred as a result of any such transactions/offers with third parties. Any questions, complaints, or claims related to any third party product or service should be directed to the appropriate vendor.
                          </li>
                          <li class="pdBtm10">
                            Pc 7 contains content that is created by Pc 7 as well as content provided by third parties. Pc 7 does not guarantee the accuracy, integrity, quality of the content provided by third parties and such content may not relied upon by the Users in utilizing the Pc 7 Services provided on Pc 7 including while participating in any of the contests hosted on Pc 7.
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li>
                      <h3 id="privacy_policy">Privacy Policy</h3>
                      <div class="t_c_content">
                        <div class="pdLt10">
                          All information collected from Users, such as registration and credit card information, is subject to Pc 7's Privacy Policy which is
                           available at   <a href="https://Pc7.in/termsNConditions">     Privacy Policy    </a>
                        </div>
                        <div class="pdLt10"></div>
                      </div>
                    </li>

                    <li>
                      <h3 id="user_conduct">User Conduct</h3>
                      <div class="t_c_content">
                        <ul>
                          <li class="pdBtm10">
                            Users agree to abide by these Terms and Conditions and all other rules, regulations and terms of use of the Website. In the event User does not abide by these Terms and Conditions and all other rules, regulations and terms of use, Pc 7 may, at its sole and absolute discretion, take necessary remedial action, including but not limited to:
                            <ul class="pdTop10">
                              <li class="pdBtm10">
                                restricting, suspending, or terminating any User's access to all or any part of Pc 7 Services;
                              </li>
                              <li class="pdBtm10">
                                deactivating or deleting a User's account and all related information and files on the account. Any amount remaining unused in the User's Unutilised Account or Winnings Account (subject to deduction of applicable TDS with effect from 1st April 2023 as applicable) on the date of deactivation or deletion shall be transferred to the User's bank account on record with Pc 7 subject to a processing fee (if any) applicable on such transfers as set out herein; or
                              </li>
                              <li class="pdBtm10">
                                refraining from awarding any prize(s) to such User.
                              </li>
                            </ul>
                          </li>
                          <li class="pdBtm10">
                            Users agree to provide true, accurate, current and complete information at the time of registration and at all other times (as required by Pc 7). Users further agree to update and keep updated their registration information
                          </li>
                          <li class="pdBtm10">
                            Users agree and acknowledge that the address related details provided, if any, by the user shall be treated as “Address on record” of the user for the purposes of Goods and Service Tax Act. In the event of the change in such details, the user shall promptly update his/her profile on the Pc 7 platform or write to Pc 7 at helpdesk.
                            <a href="https://Pc7.in/termsNConditions" rel="nofollow">
                              helpdesk
                            </a>
                          </li>
                          <li class="pdBtm10">
                            A User shall not register or operate more than one User account with Pc 7.
                          </li>
                          <li class="pdBtm10">
                            Users agree to ensure that they can receive all communication from Pc 7 by marking e-mails or sending SMSs from Pc 7 as part of their "safe senders" list. Pc 7 shall not be held liable if any e-mail/SMS remains unread by a User as a result of such e-mail getting delivered to the User's junk or spam folder.
                          </li>
                          <li class="pdBtm10">
                            Users are responsible for maintaining the confidentiality of their accounts and passwords. Users agree to immediately notify Pc 7 of any unauthorized use of accounts or any other breach of security.
                          </li>
                          <li class="pdBtm10">
                            Users agree to exit/log-out of their accounts at the end of each Session . Pc 7 shall not be responsible for any loss or damage that may result if the User fails to comply with these requirements.
                          </li>
                          <li class="pdBtm10">
                            Users agree not to use cheats, exploits, automation, software, bots, hacks or any unauthorised third party software designed to modify or interfere with Pc 7 Services and/or Pc 7 experience or assist in such activity.
                          </li>
                          <li class="pdBtm10">
                            Users agree not to copy, modify, rent, lease, loan, sell, assign, distribute, reverse engineer, grant a security interest in, or otherwise transfer any right to the technology or software underlying Pc 7 or Pc 7’s Services.
                          </li>
                          <li class="pdBtm10">
                            Users agree that without Pc 7's express written consent, they shall not modify or cause to be modified any files or software that are part of Pc 7's Services.
                          </li>
                          <li class="pdBtm10">
                            Users agree not to disrupt, overburden, or aid or assist in the disruption or overburdening of (a) any computer or server used to offer or support Pc 7 or the Pc 7’s Services (each a "Server"); or (2) the enjoyment of Pc 7 Services by any other User or person.
                          </li>
                          <li class="pdBtm10">
                            Users agree not to institute, assist or become involved in any type of attack, including without limitation to distribution of a virus, denial of service, or other attempts to disrupt Pc 7 Services or any other person's use or enjoyment of Pc 7 Services.
                          </li>
                          <li class="pdBtm10">
                            Users shall not attempt to gain unauthorised access to the User accounts, Servers or networks connected to Pc 7 Services by any means other than the User interface provided by Pc 7, including but not limited to, by circumventing or modifying, attempting to circumvent or modify, or encouraging or assisting any other person to circumvent or modify, any security, technology, device, or software that underlies or is part of Pc 7 Services.
                          </li>
                          <li class="pdBtm10">
                            User shall not to publish any content that is patently false and untrue, and is written or published in any form, with the intent to mislead or harass a person, entity or agency for financial gain or to cause any injury to any person.
                          </li>
                          <li class="pdBtm10">
                            Without limiting the foregoing, Users agree not to use Pc 7 for any of the following:
                            <ul class="pdTop10">
                              <li class="pdBtm10">
                                To engage in any obscene, offensive, indecent, racial, communal, anti-national, objectionable, defamatory or abusive action or communication;
                              </li>
                              <li class="pdBtm10">
                                To harass, stalk, threaten, or otherwise violate any legal rights of other individuals;
                              </li>
                              <li class="pdBtm10">
                                To publish, post, upload, e-mail, distribute, or disseminate (collectively, "Transmit") any inappropriate, profane, defamatory, infringing, obscene, indecent, or unlawful content;
                              </li>
                              <li class="pdBtm10">
                                To Transmit files that contain viruses, corrupted files, or any other similar software or programs that may damage or adversely affect the operation of another person's computer, Pc 7, any software, hardware, or telecommunications equipment;
                              </li>
                              <li class="pdBtm10">
                                To advertise, offer or sell any goods or services for any commercial purpose on Pc 7 without the express written consent of Pc 7;
                              </li>
                              <li class="pdBtm10">
                                To download any file, recompile or disassemble or otherwise affect our products that you know or reasonably should know cannot be legally obtained in such manner;
                              </li>
                              <li class="pdBtm10">
                                To falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or the source of software or other material;
                              </li>
                              <li class="pdBtm10">
                                To restrict or inhibit any other user from using and enjoying any public area within our sites;
                              </li>
                              <li class="pdBtm10">
                                To collect or store personal information about other Users;
                              </li>
                              <li class="pdBtm10">
                                To interfere with or disrupt Pc 7, servers, or networks;
                              </li>
                              <li class="pdBtm10">
                                To impersonate any person or entity, including, but not limited to, a representative of Pc 7, or falsely state or otherwise misrepresent User's affiliation with a person or entity;
                              </li>
                              <li class="pdBtm10">
                                To forge headers or manipulate identifiers or other data in order to disguise the origin of any content transmitted through Pc 7 or to manipulate User's presence on Pc 7(s);
                              </li>
                              <li class="pdBtm10">
                                To take any action that imposes an unreasonably or disproportionately large load on our infrastructure;
                              </li>
                              <li class="pdBtm10">
                                To engage in any illegal activities. You agree to use our bulletin board services, chat areas, news groups, forums, communities and/or message or communication facilities (collectively, the "Forums") only to send and receive messages and material that are proper and related to that particular Forum.
                              </li>
                              <li class="pdBtm10">
                                To engage in any action that threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign States, or public order, or causes incitement to the commission of any cognisable offence or prevents investigation of any offence or is insulting other nation.
                              </li>
                            </ul>
                          </li>
                          <li class="pdBtm10">
                            If a User chooses a username that, in Pc 7's considered opinion is obscene, indecent, abusive or that might subject Pc 7 to public disparagement or scorn, or a name which is an official Basket /league/franchise names and/or name of any sporting personality, as the case may be, Pc 7 reserves the right, without prior notice to the User, to restrict usage of such names, which in Pc 7’s opinion fall within any of the said categories and/or change such username and intimate the User or delete such username and posts from Pc 7, deny such User access to Pc 7, or any combination of these options.
                          </li>
                          <li class="pdBtm10">
                            Unauthorized access to Pc 7 is a breach of these Terms and Conditions, and a violation of the law. Users agree not to access Pc 7 by any means other than through the interface that is provided by Pc 7 for use in accessing Pc 7. Users agree not to use any automated means, including, without limitation, agents, robots, scripts, or spiders, to access, monitor, or copy any part of our sites, except those automated means that we have approved in advance and in writing.
                          </li>
                          <li class="pdBtm10">
                            Use of Pc 7 is subject to existing laws and legal processes. Nothing contained in these Terms and Conditions shall limit Pc 7's right to comply with governmental, court, and law-enforcement requests or requirements relating to Users' use of Pc 7.
                          </li>
                          <li class="pdBtm10">
                            Users may reach out to Pc 7 through -
                            <ul>
                              <li>
                                <a href="https://Pc7.in/termsNConditions" rel="nofollow">Helpdesk</a>
                                if the user has any concerns with regard to a Session and/or contest within Forty Eight (48) hours of winner declaration for the concerned contest.
                              </li>
                            </ul>
                          </li>
                          <li class="pdBtm10">
                            Persons below the age of eighteen (18) years are not allowed to participate on any of the contests, games (by whatever name called) on the Pc 7 Platform. The Users will have to disclose their real age at the time of getting access into the Pc 7 Platform.
                          </li>
                          <li class="pdBtm10">
                            Pc 7 may not be held responsible for any content contributed by Users on the Pc 7.
                          </li>
                          <li class="pdBtm10">
                            User hereby confirms that he / she is participating in a contest in personal capacity and not in the course of business and /or profession.
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li>
                      <h3 id="condition_of_participation">
                        Conditions of Participation:
                      </h3>
                      <div class="t_c_content">
                        <div class="pdBtm10">
                          By entering a Contest, user agrees to be bound by these Terms and the decisions of Pc 7. Subject to the terms and conditions stipulated herein below, the Pc 7, at its sole discretion, may disqualify any user from a Contest, refuse to award benefits or prizes and require the return of any prizes, if the user engages in unfair conduct, which the Pc 7 deems to be improper, unfair or otherwise adverse to the operation of the Contest or is in any way detrimental to other Users which includes, but is not limited to:
                        </div>
                        <ul>
                          <li class="pdBtm10">
                            Falsifying ones’ own personal information (including, but not limited to, name, email address, bank account details and/or any other information or documentation as may be requested by Pc 7 to enter a contest and/or claim a prize/winning.;
                          </li>
                          <li class="pdBtm10">
                            Engaging in any type of financial fraud or misrepresentation including unauthorized use of credit/debit instruments, payment wallet accounts etc. to enter a Contest or claim a prize. It is expressly clarified that the onus to prove otherwise shall solely lie on the user.;
                          </li>
                          <li class="pdBtm10">
                            Colluding with any other user(s) or engaging in any type of syndicate play;
                          </li>
                          <li class="pdBtm10">
                            Any violation of Contest rules or the Terms of Use;
                          </li>
                          <li class="pdBtm10">
                            Accumulating points or prizes through unauthorized methods such as automated bots, or other automated means;
                          </li>
                          <li class="pdBtm10">
                            Using automated means (including but not limited to harvesting bots, robots, parser, spiders or screen scrapers) to obtain, collect or access any information on the Website or of any User for any purpose
                          </li>
                          <li class="pdBtm10">
                            Any type of Cash Bonus misuse, misuse of the Invite Friends program, or misuse of any other offers or promotions;
                          </li>
                          <li class="pdBtm10">
                            Tampering with the administration of a Contest or trying to in any way tamper with the computer programs or any security measure associated with a Contest;
                          </li>
                          <li class="pdBtm10">
                            Obtaining other users’ information without their express consent and/or knowledge and/or spamming other users (Spamming may include but shall not be limited to send unsolicited emails to users, sending bulk emails to Pc 7 Users, sending unwarranted email content either to selected Users or in bulk); or
                          </li>
                          <li class="pdBtm10">
                            Abusing the Website in any way (‘unparliamentary language, slangs or disrespectful words’ are some of the examples of Abuse)
                          </li>
                        </ul>
                        <div class="pdBtm10">
                          It is clarified that in case a User is found to be in violation of this policy, Pc 7 reserves its right to initiate appropriate Civil/Criminal remedies as it may be advised other than forfeiture and/or recovery of prize money if any.
                        </div>
                      </div>
                    </li>

                    <li>
                      <h3 id="registration_contest">
                        Registration for a Contest
                      </h3>
                      <div class="t_c_content">
                        <ul>
                          <li class="pdBtm10">
                            In order to register for the Contest(s), Participants are required to accurately provide the following information:
                            <ul class="pdTop10">
                              <li>Full Name</li>
                              <li>Basket  Name(s)</li>
                              <li>E-mail Address</li>
                              <li>Password</li>
                              <li>State of Residence</li>
                              <li>Gender</li>
                              <li>Date of Birth</li>
                            </ul>
                          </li>
                          <li class="pdBtm10">
                            Participants are also required to confirm that they have read, and shall abide by, these Terms and Conditions.
                          </li>
                          <li class="pdBtm10">
                            In the event a Participant indicates, while entering an address, that he/she is a resident of either Andhra Pradesh, Assam, Nagaland, Odisha, Sikkim or Telangana such Participant will not be permitted to proceed to sign up for any Session in the paid version of the Contest as described below.
                          </li>
                          <li class="pdBtm10">
                            Once the Participants have entered the above information, and clicked on the "register" tab, and such Participants are above the age of 18 years, they are sent an email confirming their registration and containing their login information.
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li>
                      <h3 id="contest_participation_prizes">
                        Contest(s), Participation and Prizes
                      </h3>
                      <div class="t_c_content">
                        <ul>
                          <li class="pdBtm10">
                            As part of its services, Pc 7 may make available the contest(s) on the Pc 7 platform.
                          </li>

                          <li class="pdBtm10">
                            Currently, following contests are made available on Pc 7 platform: 1) A  <a href="https://Pc7.in/termsNConditions" title="Play Fantasy Games with Pc 7">Fantasy Games</a> game, 2) A fantasy football game, 3) A fantasy kabaddi game, 4) A fantasy basketball game, 5) A fantasy volleyball game, 6) A fantasy hockey game, 7) A fantasy baseball game, 8) A fantasy American football game and 9) A fantasy handball game. Individual users wishing to participate in the such contest ("“Participants"”) are invited to create their own fantasy Basket s ("“Basket /s"”) consisting of real life cricketers, footballers, basketball Crypto, volleyball Crypto, hockey Crypto, baseball Crypto, handball Crypto, American football Crypto or kabaddi Crypto (as applicable) involved in the real-life cricket/football/kabaddi/volleyball/hockey/basketball/baseball/handball/American football Session (as applicable), series or tournament (each a "Sport Event") to which the fantasy game relates. Pc 7 offers its platform to Participants for fantasy game Contest(s) being created relating to each Sport Event, and Participants can participate in such Contest(s) with their Basket s.
                          </li>
                          <li class="pdBtm10">
                            Depending upon the circumstances of each match, the participants can edit their Basket s till the official Session start time as declared by the officials of the Sport Event or adjusted deadline, as specified below.
                            <ul>
                              <li>
                                Pc 7 reserves the right to abandon a specific round or adjust the deadline of a round in certain specific, uncertain scenarios, which are beyond Pc 7’s reasonable control, including but not limited to the ones’ mentioned herein below:
                                <ol>
                                  <li>
                                    <h5>
                                      Actual Session start time is before the official deadline:
                                    </h5>
                                    <ul>
                                      <li>
                                        All sports other than Cricket-
                                        <ul>
                                          <li>
                                            Pc 7 reserves the right to adjust the deadline to a maximum of 10 minutes post actual Session start time. In case the actual Session start time is more than 10 minutes of official deadline, the contest will be abandoned.
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        Cricket -
                                        <ul>
                                          <li>
                                            Pc 7 reserves the right to adjust the deadline by a Maximum of 10 minutes or 3 overs bowled, whichever is less, before the official Session start time.
                                          </li>
                                          <li>
                                            In cases where official Session time cannot be verified by Pc 7 through reliable and/or publicly available sources, Pc 7 reserves the right to adjust the deadline to such a time by which a maximum of 3 overs in the given Session are bowled.
                                          </li>
                                        </ul>
                                      </li>
                                    </ul>
                                  </li>
                                  <li>
                                    <h5>
                                      Actual Session start time is after the official deadline:
                                    </h5>
                                    <p>
                                      Pc 7 reserves the right to extend the deadline or abandon the contest/game based on the circumstances such as delayed toss, interruption on account of weather, non-appearance of Basket s, technical/equipment glitches causing delays etc.
                                    </p>
                                  </li>
                                </ol>
                              </li>
                            </ul>
                          </li>

                          <li class="pdBtm10">
                            Pc 7 shall endeavor to send communications through emails and/or SMS communication, about any such change as is contemplated in the aforementioned paragraphs to keep the User updated.
                          </li>
                          <li class="pdBtm10">
                            Basket s are awarded points on the basis of the real life cricketers', footballers', basketball Crypto, volleyball Crypto, hockey Crypto, baseball Crypto, handball Crypto, American football Crypto or kabaddi Crypto (as applicable) performances at the end of a designated match, Session or tournament of the Contest(s). The Participant(s) whose Basket (s) have achieved the highest aggregate score(s) in the Contest(s) shall be declared winners ("Winners"). In certain pre-specified Contests, there may be more than one Winner and distribution of prizes to such Winners will be in increasing order of their Basket 's aggregate score at the end of the designated match(s) of the Contests.
                          </li>
                          <li class="pdBtm10">
                            The Contest(s) across the Pc 7 Services shall, in addition to the Terms and Conditions, rules and regulations mentioned herein, be governed by:
                            <ul>
                              <li>
                                "Fantasy Rules" available at
                                <a href="https://Pc7.in/termsNConditions">
                                  How To Play -&nbsp;Fantasy Games
                                </a>
                                &nbsp;or
                                <a
                                  href="https://Pc7.in/termsNConditions"

                                >
                                  How To Play&nbsp;Fantasy Football
                                </a>
                                &nbsp;or
                                <a
                                  href="https://Pc7.in/termsNConditions"

                                >
                                  How To Play -&nbsp;Fantasy Kabaddi
                                </a>
                                or
                                <a href="https://Pc7.in/termsNConditions">
                                  How To Play -&nbsp;Fantasy Volleyball
                                </a>
                                or
                                <a
                                  href="https://Pc7.in/termsNConditions"

                                >
                                  How to Play Fantasy Hockey
                                </a>
                                or
                                <a
                                  href="https://Pc7.in/termsNConditions"

                                >
                                  How to Play Fantasy Basketball
                                </a>
                                or
                                <a
                                  href="https://Pc7.in/termsNConditions"

                                >
                                  How to Play-Baseball
                                </a>
                                or
                                <a
                                  href="https://Pc7.in/termsNConditions"

                                >
                                  How to Play – Fantasy Handball
                                </a>
                              </li>
                              or
                              <a
                                href="https://Pc7.in/termsNConditions"

                              >
                                How to Play – American football
                              </a>
                              <li>
                                Other rules and regulations (including rules and regulation in relation to any payments made to participate in the Contest(s); and all Participants agree to abide by the same.
                              </li>
                            </ul>
                          </li>
                          <li class="pdBtm10">
                            Currently, there are paid versions of the Contest(s) made available on Pc 7 platform. Users may participate in the Contest(s) by paying the pre-designated amount as provided on the relevant Contest page. The ‘pre-designated amount’ means and includes pre-determined platform fee for accessing Pc 7 services and pre-determined participant'’s contribution towards prize money pool. The Participant with the highest aggregate points at the end of the pre-determined Session shall be eligible to win a pre-designated prize which is disbursed out of prize money pool, as stated on the relevant Contest(s) page.
                          </li>
                          <li class="pdBtm10">
                            A Participant may create different Basket s for participation in Contest(s) in relation to a Sport Event across the Pc 7 Services. However, unless Pc 7 specifies otherwise in relation to any Contest ("“Multiple Entry Contest"”), Participants acknowledge and agree that they may enter only one Basket  in any Contest offered in relation to a Sport Event. In case of Multiple Entry Contest(s), a Participant may enter more than one Basket  in a single Multiple Entry Contest. In addition, it is expressly clarified that Pc 7 may, from time to time, restrict the maximum number of Basket s that may be created by a single User account (for each format of the contest) or which a single User account may enter in a particular Multiple Entry Contest, in each case to such number as determined by Pc 7 in its sole discretion.
                          </li>
                          <li class="pdBtm10">
                            Participant shall pay a pre-designated amount for participating in the contest(s) being created on the Pc 7 platform.
                            In the event a Participant indicates, while entering an address, that he/she is a resident of either Andhra Pradesh, Assam, Nagaland, Odisha, Sikkim or Telangana such Participant will not be permitted to proceed to sign up for the Session or contest and may not participate in any paid version of the Contest(s).

                          </li>
                          <li class="pdBtm10">
                            In two members and above public contests, where all participants have entered the contest with the exact same Basket s, including the captain and vice-captain in such event, contest prize money shall be equally divided amongst all participants and the amount shall be deposited in the Pc 7 winning account of all participants and the remaining amount shall be credited in the Cash Bonus account of such participants. In the event a user is found to be violating this policy, the Company reserves the right to initiate appropriate action against such users as it deems fit, which shall also include forfeiture of the Cash Bonus Amount.
                          </li>
                          <li class="pdBtm10">
                            In Starting Lineup Feature : Purpose of indicating a player's inclusion in final starting lineup is only to provide information and assist a user in selecting his/her Basket  on Pc 7. While indicating a Player's inclusion in starting lineup is given by Pc 7 on the basis of information/data received through feed providers, publicly available information. Users are advised to do a thorough research of their own from official sources and/or other available sources of information. Pc 7, shall not take any liability, if a player earlier indicated as 'Playing' does not play or start for any reason whatsoever.
                          </li>
                          <li class="pdBtm10">
                            Substitutes on the field will not be awarded points for any contribution they make. However, 'Concussion or COVID-19 or X Factor or Impact Player Substitutes' will ONLY be awarded points four (4) points for making an appearance in a Session in addition to points for any contribution they make as per the Fantasy Points System (w.e.f Oct 9 2022).
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li>
                      <h3 id="contest_formats">Contest Formats</h3>
                      <div class="t_c_content">
                        <ul>
                          <li class="pdBtm10">
                            Currently, two formats of contests are made available on Pc 7 platform (1) Public Contests, where Users can participate in a Contest with other Users without any restriction on participation and (2) Private Contests, where Users can invite specific Users into a Contest and restrict participation to such invited Users. A user can enter into a maximum of 2,000 contests (including both Public and Private contests) for any cricket Session and a maximum of 500 contests for any other non-cricket Session on Pc 7. Any participation in contests over the set limit shall be automatically prohibited. All rules applicable to Contests as set out herein shall be applicable to both formats of Contests.
                          </li>
                          <li class="pdBtm10">
                            <strong>Public contest</strong>
                            <ul class="pdTop10">
                              <li class="pdBtm10">
                                In the Public contest format of the Contest(s), Pc 7 may make available the Contest(s) comprising of 2 -– 100 Participants or any other pre-designated number of Participants.
                              </li>
                              <li class="pdBtm10">
                                Pc 7 may create this format of the Contest(s) as a paid format and the Winner will be determinable at the end of the Session as per rule of the contests.
                              </li>
                              <li class="pdBtm10">
                                The number of Participants required to make the Contest(s) operational will be pre-specified and once the number of Participants in such Contest(s) equals the pre-specified number required for that Contest(s), such Contest(s) shall be operational. In case the number of Participants is less than the pre-specified number at the time of commencement of the match, such Contest(s) will not be operational and the pre-designated amount paid by each Participant shall be returned to the account of such User without any charge or deduction.
                              </li>
                              <li class="pdBtm10">
                                In certain Contests across the Pc 7 Services, designated as “Guaranteed contests”, the Contest(s) shall become operational only when a minimum of two users join a Guaranteed Contest. The pre-specified number of winners to be declared in such Contest(s), even if all available Participant slots (as pre-specified in relation to the Contest(s)) remain unfilled. It is clarified that notwithstanding the activation of such Contest(s), Participants can continue to join such Contest(s) till either (i) all available Participant slots of such Contest(s) are filled or (ii) the Session to which the Contest (s) relates commences, whichever is earlier. In the event of shortfall in the number of participants joining the Guaranteed Contest, Pc 7 shall continue with such contests and the short fall in the prize pool shall be borne by Pc 7.
                              </li>
                            </ul>
                          </li>
                          <li class="pdBtm10">
                            <strong>Private contest</strong>
                            <ul class="pdTop10">
                              <li class="pdBtm10">
                                In the Private contest format of the Contest(s), Pc 7 enables Users to create a contest ("“Private contest"”) and invite other users, whether existing Users or otherwise, ("“Invited User"”) to create Basket s and participate in the Contest(s). Users may create a Private contest to consist of a pre-specified number of Participants, that is, consisting of either 2-20000 Participants. The User creating the Private contest shall pay the pre-designated amount for such Private contest and thereby join that Private contest and shall supply a name for the Private contest and be provided with a unique identification code ("contest Code") (which will be issued to the account of such User). The User agrees and understands that once the Private contest is created no change shall be permitted in the terms or constitution of the Private contest, except for a change in the name of the contest. The User creating the Private contest shall provide Pc 7 with the email address or Facebook account username of Invited Users to enable Pc 7 to send a message or mail inviting such Invited User to register with Pc 7 (if necessary) and participate in the Private contest in relation to which the invite has been issued.
                              </li>
                              <li class="pdBtm10">
                                In order to participate in the Private contest, an Invited User shall input the contest Code associated with the Private contest and pay the pre-designated amount for the Private contest. Once the number of Participants in a Private contest equals the number of pre-specified Participants for that Private contest, the Private contest shall be rendered operative and no other Invited Users or Users shall be permitted to participate in the Private contest. In the event that any Private contest does not contain the pre-specified number of Participants for that Private contest within 1 hour prior to the commencement of the match/Contest, the Platform will initiate an automatic refund of the amount deposited. Such refund shall be processed after the expiry of the deadline for filling of participants for such Private Contest.
                              </li>
                              <li class="pdBtm10">
                                It is clarified that the participation of Invited Users in any Private contest is subject to the pre-specified number of Participants for that Private contest, and Pc 7 shall not be liable to any person for the inability of any Invited User to participate in any Private contest due to any cause whatsoever, including without limitation due to a hardware or technical malfunction or lack of eligibility of such Invited User to participate in the Contest(s).
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li>
                      <h3 id="legality">Legality of Game of Skill</h3>
                      <div class="t_c_content">
                        <ul>
                          <li class="pdBtm10">
                            Games of skill are legal, as they are excluded from the ambit of Indian gambling legislations including, the Public Gambling Act of 1867.The Indian Supreme Court in the cases of State of Andhra Pradesh v. K Satyanarayana (AIR 1968 SC 825) and KR Lakshmanan v. State of Tamil Nadu (AIR 1996 SC 1153) has held that a game in which success depends predominantly upon the superior knowledge, training, attention, experience and adroitness of the player shall be classified as a game of skill.
                          </li>
                          <li class="pdBtm10">
                            The Contest (s) described above (across the Pc 7 Services) are games of skill as success of Participants depends primarily on their superior knowledge of the games of cricket and/or football and/or basketball and/or hockey and/or handball and/ or volleyball and/or kabaddi and/or American football and/or baseball statistics, knowledge of Crypto' relative form, Crypto' performance in a particular territory, conditions and/or format (such as ODIs, test cricket and Twenty20 in the cricket fantasy game), attention and dedication towards the Contest(s) and adroitness in playing the Contest(s). The Contest(s) also requires Participants to field well-balanced sides with limited resources and make substitutions at appropriate times to gain the maximum points.
                          </li>
                          <li class="pdBtm10">
                            By participating in this Contest(s), each Participant acknowledges and agrees that he/she is participating in a game of skill.
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li>
                      <h3 id="eligibility">Eligibility</h3>
                      <div class="t_c_content">
                        <ul>
                          <li class="pdBtm10">
                            The Contest(s) are open only to persons above the age of 18 years.
                          </li>
                          <li class="pdBtm10">
                            The Contest(s) are open only to persons, currently residing in India.
                          </li>
                          <li class="pdBtm10">
                            Pc 7 may, in accordance with the laws prevailing in certain Indian states, bar individuals residing in those states from participating in the Contest(s). Currently, individuals residing in the Indian states of Andhra Pradesh, Assam, Nagaland, Odisha, Sikkim or Telangana may not participate in the paid version of the Contest as the laws of these states are unclear/ bar persons from participating in games of skill where participants are required to pay to enter.
                          </li>
                          <li class="pdBtm10">
                            Persons who wish to participate must have a valid email address.
                          </li>
                          <li class="pdBtm10">
                            Pc 7 may on receipt of information bar a person from participation and/or withdrawing winning amounts if such person is found to be one with insider knowledge of participating Basket s in any given contests/match, organizing boards, leagues etc.
                          </li>
                          <li class="pdBtm10">
                            Only those Participants who have successfully registered on the Pc 7 as well as registered prior to each Session in accordance with the procedure outlined above shall be eligible to participate in the Contest and win prizes.
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li>
                      <h3 id="payment_terms">Payment Terms</h3>
                      <div class="t_c_content">
                        <ul>
                          <li class="pdBtm10">
                            Users by participating in a Contest(s) hereby authorize Pc 7 to appoint a third party/ Trustee/Escrow Agent to manage users funds on users behalf. In respect of any transactions entered into on the Pc 7 platform, including making a payment to participate in the paid versions of Contest(s), Users agree to be bound by the following payment terms:
                            <ul class="pdTop10">
                              <li class="pdBtm10">
                                The payment of pre-designated amount Users make to participate in the Contest(s) is inclusive of the pre-designated platform fee (inclusive of applicable GST) for access to the Pc 7 Services charged by Pc 7 and pre-determined participant’s contribution towards prize money pool.
                              </li>
                              <li class="pdBtm10">
                                Subject to these Terms and Conditions, all amounts collected from the User are held in a separate non-interest earning bank Accounts. The said accounts are operated by a third party appointed by Pc 7 in accordance with Clause 10 of these Terms and Conditions. From these bank accounts, the payouts can be made to (a) Users (towards their withdrawals), (b) Pc 7 (towards its Platform Fees) and to (c) Government (towards TDS on Net Winnings). Pc 7 receives only its share of the platform Fees through the said Escrow Agent.
                              </li>
                              <li class="pdBtm10">
                                The Pc 7 reserves the right to charge a Platform Fee, prior to a User's joining of such Contest. The Platform Fee (inclusive of applicable Goods and Service Tax (“GST”) thereon) will be debited from the User’s account balance and Pc 7 shall issue an invoice for such debit to the User. User can view the GST paid on each paid contest join through invoices on ‘My Transaction’ page. The GST value listed on the users invoice shall be the final GST amount charged to the user.
                              </li>
                              <li class="pdBtm10">
                                The User may participate in a Contest wherein the User has to contribute a pre-specified contribution towards the Prize Money Pool of such Contest, which will be passed on to the Winner(s) of the Contest after the completion of the Contest as per the terms and conditions of such Contest. It is clarified that Pc 7 has no right or interest in this Prize Money Pool, and only acts as an intermediary engaged in collecting and distributing the Prize Money Pool in accordance with the Contest terms and conditions. The amount to be paid-in by the User towards the Prize Money Pool would also be debited from the User’s account balance maintained with the Trustee.
                              </li>
                              <li class="pdBtm10">
                                Any user availing Pc 7 services are provided with two categories of accounts for the processing and reconciliation of payments: (i) 'Unutilized' Account, (ii) Winnings Account. It is clarified that in no instance the transfer of any amounts in the User's accounts to any other category of account held by the user or any third party account, including a bank account held by a third party:
                                <ul>
                                  <li class="pdBtm10">
                                    User's winnings in any Contest will reflect as credits to the User's Winnings Account.
                                  </li>
                                  <li class="pdBtm10">
                                    User’s remitting the amount through the designated payment gateway shall be credited to User’s Unutlized Account’.
                                  </li>
                                </ul>
                              </li>
                              <li class="pdBtm10">
                                Each time a User participates in any contest on Pc 7 platform, the pre-designated amount shall be debited in the User’s account. In debiting amounts from the User’s accounts towards the pre-designated amount of such user shall be debited from the User’s Unutilized Account and thereafter, any remaining amount of participation fee shall be debited from the User’s Winning Account.
                              </li>
                              <li class="pdBtm10">
                                In case there is any amount remaining to be paid by the User in relation to such User’s participation in any match(s) or Contest(s), the User will be taken to the designated payment gateway to give effect to such payment. In case any amount added by the User through such payment gateway exceeds the remaining amount of the pre-designated amount, the amount in excess shall be transferred to the User’s ‘Unutilized’ Account and will be available for use in participation in any match(s) or Contest(s).
                              </li>
                              <li class="pdBtm10">
                                Debits from the ‘Unutilized’ Account for the purpose of enabling a user’s participation in a Contest shall be made in order of the date of credit of amounts in the ‘Unutilized’ Account, and accordingly amounts credited into ‘Unutilized’ Account earlier in time shall be debited first.
                              </li>
                              <li class="pdBtm10">
                                Withdrawal of any amount standing to the User's credit in the Winnings Account may be made by way of a request to Pc 7. Pc 7 shall effect an online transfer to the User's bank account on record with Pc 7 within a commercially reasonable period of time. Such transfer will reflect as a debit to the User's Winnings Account. Pc 7 shall not charge any processing fee for the online transfer of such amount from the Winnings Account to the User's bank account on record with Pc 7. Users are requested to note that they will be required to provide valid photo identification, Pan Card and address proof documents for proof of identity and address in order for Pc 7 to process the withdrawal request. The name mentioned on the User's photo identification document should correspond with the name provided by the User at the time of registration on Pc 7, as well as the name and address existing in the records of the User's bank account as provided to Pc 7. In the event that no bank account has been registered by the User against such User's account with Pc 7, or the User has not verified his/her User account with Pc 7, to Pc 7's satisfaction and in accordance with these Terms and Conditions, and in case the User fails to register a bank account with his/her User Account and/or to verify his/her User Account, Pc 7 shall not transfer any Winning amounts to the User.
                              </li>
                              <li class="pdBtm10">
                                Further, in order to conduct promotional activities, Pc 7 may gratuitously issue Cash Bonus to the User for the purpose of participation in any Contest(s) and no User shall be permitted to transfer or request the transfer of any amount in to the Cash Bonus. The usage of any Cash Bonus issued shall be subject to the limitations and restrictions, including without limitation, restrictions as to time within which such Cash Bonus must be used, as applied by Pc 7 and notified to the User at the time of issue of such amount. The issue of any Cash Bonus to the user is subject to the sole discretion of Pc 7 and cannot be demanded by any User as a matter of right. The issue of any Cash Bonus by Pc 7 on any day shall not entitle the user to demand the issuance of such Cash Bonus at any subsequent period in time nor create an expectation of recurring issue of such Cash Bonus by Pc 7 to such User. The Cash Bonus granted to the user may be used by such User for the purpose of setting off against the contribution to prize pool in any Contest, in accordance with these Terms and Conditions. The Cash Bonus shall not be withdraw-able or transferrable to any other account of the User, including the bank account of such User, or of any other User or person, other that as part of the winnings of a User in any Contest(s). In case the User terminates his/her account with Pc 7 or such account if terminated by Pc 7, all Cash Bonus granted to the user shall return to Pc 7 and the User shall not have any right or interest on such Cash Bonus.
                              </li>
                              <li class="pdBtm10">
                                All Cash Bonus credited in the User account shall be valid for a period of 14 days from the date of credit. The unutilized Cash Bonus shall expire at the end of 14 days from the date of credit.
                              </li>
                              <li class="pdBtm10">
                                Users agree that once they confirm a transaction on Pc 7, they shall be bound by and make payment for that transaction.
                              </li>
                              <li class="pdBtm10">
                                The User acknowledges that subject to time taken for bank reconciliations and such other external dependencies that Pc 7 has on third parties, any transactions on Pc 7 Platform may take up to 24 hours to be processed. Any amount paid or transferred into the User's 'Unutilized' Account or Winnings Account may take up to 24 hours to reflect in the User's 'Unutilized' Account or Winnings Account balance. Similarly, the utilization of the Cash Bonus or money debited from 'Unutilized' Account or Winnings Account may take up to 24 hours to reflect in the User's 'Unutilized' Account or Winnings Account balance. Users agree not to raise any complaint or claim against Pc 7 in respect of any delay, including any lost opportunity to join any Contest or Session due to delay in crediting of transaction amount into any of the User's accounts
                                A transaction, once confirmed, is final and no cancellation is permissible.
                                Pc 7 may, in certain exceptional circumstances and at its sole and absolute discretion, refund the amount to the User after deducting applicable cancellation charges and taxes. At the time of the transaction, Users may also be required to take note of certain additional terms and conditions and such additional terms and conditions shall also govern the transaction. To the extent that the additional terms and conditions contain any clause that is conflicting with the present terms and conditions, the additional terms and conditions shall prevail.

                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li>
                      <h3 id="tabulation">Tabulation of Fantasy Points</h3>
                      <div class="t_c_content">
                        <div class="pdLt20">
                          Pc 7 may obtain the score feed and other information required for the computation and tabulation of fantasy points from third party service provider(s) and/or official website of the Session organiser. In the rare event that any error in the computation or tabulation of fantasy points, selection of winners, abandonment of a Session etc., as a result of inaccuracies in or incompleteness of the feed provided by the third party service provider and/or official website of the Session organiser comes to its attention, Pc 7 shall use best efforts to rectify such error prior to the distribution of prizes. However, Pc 7 hereby clarifies that it relies on the accuracy and completeness of such third party score/statistic feeds and does not itself warrant or make any representations concerning the accuracy thereof and, in any event, shall take no responsibility for inaccuracies in computation and tabulation of fantasy points or the selection of winners as a result of any inaccurate or incomplete scores/statistics received from such third party service provider. Users and Participants agree not to make any claim or raise any complaint against Pc 7 in this respect.
                        </div>
                      </div>
                    </li>

                    <li>
                      <h3 id="selection_verification">
                        Selection and Verification of Winners and Conditions relating to the Prizes
                      </h3>
                      <div class="t_c_content">
                        <ul>
                          <li class="pdBtm10">
                            <strong>Selection of Winners</strong> <br />
                            Winners will be decided on the basis of the scores of the Basket s in a designated Session (which may last anywhere between one day and an entire tournament) of the Contest(s). The Participant(s) owning the Basket (s) with the highest aggregate score in a particular Session shall be declared the Winner(s). In certain pre-specified Contests, Pc 7 may declare more than one Winner and distribute prizes to such Winners in increasing order of their Basket 's aggregate score at the end of the designated Session of the Contest. The contemplated number of Winners and the prize due to each Winner in such Contest shall be as specified on the Contest page prior to the commencement of the Contest.
                            Participants creating Basket s on behalf of any other Participant or person shall be disqualified.
                            In the event of a tie, the winning Participants shall be declared Winners and the prize shall be equally divided among such Participants.
                            Pc 7 shall not be liable to pay any prize if it is discovered that the Winner(s) have not abided by these Terms and Conditions, and other rules and regulations in relation to the use of the Pc 7, Contest, "Fantasy Rules", etc.
                          </li>
                          <li class="pdBtm10">
                            <strong>Contacting Winners</strong> <br />
                            Winners shall be contacted by Pc 7 or the third party conducting the Contest on the e-mail address provided at the time of registration. The verification process and the documents required for the collection of prize shall be detailed to the Winners at this stage. As a general practice, winners will be required to provide following documents:
                            <ul class="pdTop10">
                              <li class="pdBtm10">
                                Photocopy of the User's PAN card;
                              </li>
                              <li class="pdBtm10">
                                Photocopy of a government-issued residence proof;
                              </li>
                              <li class="pdBtm10">
                                User's bank account details and proof of the same.
                              </li>
                            </ul>
                            Pc 7 shall not permit a Winner to withdraw his/her prize(s)/accumulated winnings unless the above-mentioned documents have been received and verified within the time-period stipulated by Pc 7. The User represents and warrants that the documents provided in the course of the verification process are true copies of the original documents to which they relate.

                            Participants are required to provide proper and complete details at the time of registration. Pc 7 shall not be responsible for communications errors, commissions or omissions including those of the Participants due to which the results may not be communicated to the Winner.

                            The list of Winners shall be posted on a separate web-page on the Pc 7. The winners will also be intimated by e-mail.

                            In the event that a Participant has been declared a Winner on the abovementioned web-page but has not received any communication from Pc 7, such Participant may contact Pc 7 within the time specified on the webpage.
                          </li>
                          <li class="pdBtm10">
                            <strong>Verification Process</strong> <br />
                            Only those Winners who successfully complete the verification process and provide the required documents within the time limit specified by Pc 7 shall be permitted to withdraw/receive their accumulated winnings (or any part thereof). Pc 7 shall not entertain any claims or requests for extension of time for submission of documents.

                            <br />
                            Pc 7 shall scrutinize all documents submitted and may, at its sole and absolute discretion, disqualify any Winner from withdrawing his accumulated winnings (or any part thereof) on the following reasons:
                            <ul class="pdTop10">
                              <li class="pdBtm10">
                                Determination by Pc 7 that any document or information submitted by the Participant is incorrect, misleading, false, fabricated, incomplete or illegible; or
                              </li>
                              <li class="pdBtm10">
                                Participant does not fulfill the Eligibility Criteria specified in Clause 10 above; or
                              </li>
                              <li class="pdBtm10">Any other match.</li>
                            </ul>
                          </li>
                          <li class="pdBtm10">
                            <strong>Taxes Payable</strong> <br />
                            <ul>
                              <li>
                                All prizes shall be subject to deduction of tax (“TDS”) as per the Income Tax Act 1961. With effect from 1st April 2023, TDS of 30% shall be deducted on Net Winnings (NW) on each withdrawal request placed by the user. With effect from 1st May 2023, Net Winnings shall be calculated as follows:
                              </li>
                              <li>
                                NW = A-B-C-D+E. Where : A= Total withdrawal (including current withdrawal amount), B= Total Deposit, C= Opening Balance at the beginning of financial year, D= Amount on which TDS was deducted. E= Closing balance of the user account at the end of financial year.
                              </li>
                            </ul>
                            <div>
                              In the event, a user does not withdraw any of his winnings during a financial year and has Net Winning balance as at 31 March of each year, then TDS of 30% shall be deducted on such Net Winnings balance from the user’s Winning Account as on 31 March. In case of any revisions by the Government of World   to the aforementioned rate or the definition of Net Winnings in the future, TDS will be deducted by Pc 7 in accordance with the then current prescribed TDS rate and the revised definition of Net Winnings. Winners will be provided TDS certificates in respect of such tax deductions. The Winners shall be responsible for payment of any other applicable taxes, including but not limited to, income tax, gift tax, etc. in respect of the Net Winnings. Pc 7 shall not in any manner be responsible for users individual tax matters.
                            </div>
                          </li>
                          <li class="pdBtm10">
                            <strong>Miscellaneous</strong> <br />
                            The decision of Pc 7 with respect to the awarding of prizes shall be final, binding and non-contestable.

                            Participants playing the paid formats of the Contest(s) confirm that they are not residents of any of the following Indian states - Andhra Pradesh, Assam, Nagaland, Odisha, Sikkim or Telangana. If it is found that a Participant playing the paid formats of the Contest(s) is a resident of any of the abovementioned states, Pc 7 shall disqualify such Participant and forfeit any prize won by such Participant. Further Pc 7 may, at its sole and absolute discretion, suspend or terminate such Participant's account with Pc 7. Any amount remaining unused in the User's Unutilised Account or Winnings Account ( subject to deduction of applicable TDS with effect from 1st April 2023 as applicable ) on the date of deactivation or deletion shall be reimbursed to the User by an online transfer to the User's bank account on record with Pc 7, subject to the processing fee (if any) applicable on such transfers as set out herein.

                            If it is found that a Participant playing the paid formats of the Contest(s) is under the age of eighteen (18), Pc 7 shall be entitled, at its sole and absolute discretion, to disqualify such Participant and forfeit his/her prize. Further, Pc 7 may, at its sole and absolute discretion, suspend or terminate such Participant's account.

                            To the extent permitted by law, Pc 7 makes no representations or warranties as to the quality, suitability or merchantability of any prizes and shall not be liable in respect of the same.

                            Pc 7 may, at its sole and absolute discretion, vary or modify the prizes being offered to winners. Participants shall not raise any claim against Pc 7 or question its right to modify such prizes being offered, prior to closure of the Contest.

                            Pc 7 will not bear any responsibility for the transportation or packaging of prizes to the respective winners. Pc 7 shall not be held liable for any loss or damage caused to any prizes at the time of such transportation.

                            The Winners shall bear the shipping, courier or any other delivery cost in respect of the prizes.

                            The Winners shall bear all transaction charges levied for delivery of cash prizes.

                            All prizes are non-transferable and non-refundable. Prizes cannot be exchanged / redeemed for cash or kind. No cash claims can be made in lieu of prizes in kind.
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li>
                      <h3 id="publicity">Publicity</h3>
                      <div class="t_c_content">
                        <div class="pdLt20 pdBtm10">
                          Acceptance of a prize by the Winner constitutes permission for Pc 7, and its affiliates to use the Winner's name, likeness, voice and comments for advertising and promotional purposes in any media World   wide for purposes of advertising and trade without any further permissions or consents and / or additional compensation whatsoever.
                          The Winners further undertake that they will be available for promotional purposes as planned and desired by Pc 7 without any charge. The exact dates remain the sole discretion of Pc 7. Promotional activities may include but not be limited to press events, internal meetings and ceremonies/functions.

                        </div>
                      </div>
                    </li>

                    <li>
                      <h3 id="general_conditions">General Conditions</h3>
                      <div class="t_c_content">
                        <div class="pdLt20 pdBtm10">
                          If it comes to the notice of Pc 7 that any governmental, statutory or regulatory compliances or approvals are required for conducting any Contest(s) or if it comes to the notice of Pc 7 that conduct of any such Contest(s) is prohibited, then Pc 7 shall withdraw and / or cancel such Contest(s) without prior notice to any Participants or winners of any Contest(s). Users agree not to make any claim in respect of such cancellation or withdrawal of the Contest or Contest it in any manner.

                          Employees are not be eligible to participate in any Public Contest(s).

                        </div>
                      </div>
                    </li>

                    <li>
                      <h3 id="dispute">Dispute and Dispute Resolution</h3>
                      <div class="t_c_content">
                        <ul>
                          <li class="pdBtm10">
                            The courts of competent jurisdiction at Mumbai shall have exclusive jurisdiction to determine any and all disputes arising out of, or in connection with, the Pc 7 Services provided by Pc 7 (including the Contest(s)), the construction, validity, interpretation and enforceability of these Terms and Conditions, or the rights and obligations of the User(s) (including Participants) or Pc 7, as well as the exclusive jurisdiction to grant interim or preliminary relief in case of any dispute referred to arbitration as given below. All such issues and questions shall be governed and construed in accordance with the laws of the Republic of India.
                          </li>
                          <li class="pdBtm10">
                            In the event of any legal dispute (which may be a legal issue or question) which may arise, the party raising the dispute shall provide a written notification ("Notification") to the other party. On receipt of Notification, the parties shall first try to resolve the dispute through discussions. In the event that the parties are unable to resolve the dispute within fifteen (15)
                            days of receipt of Notification, the dispute shall be settled by arbitration.

                          </li>
                          <li class="pdBtm10">
                            The place of arbitration shall be Mumbai, India. All arbitration proceedings shall be conducted in English and in accordance with the provisions of the Arbitration and Conciliation Act, 1996, as amended from time to time.
                          </li>
                          <li class="pdBtm10">
                            The arbitration award will be final and binding on the Parties, and each Party will bear its own costs of arbitration and equally share the fees of the arbitrator unless the arbitral tribunal decides otherwise. The arbitrator shall be entitled to pass interim orders and awards, including the orders for specific performance and such orders would be enforceable in competent courts. The arbitrator shall give a reasoned award.
                          </li>
                          <li class="pdBtm10">
                            Nothing contained in these Terms and Conditions shall prevent Pc 7 from seeking and obtaining interim or permanent equitable or injunctive relief, or any other relief available to safeguard Pc 7's interest prior to, during or following the filing of arbitration proceedings or pending the execution of a decision or award in connection with any arbitration proceedings from any court having jurisdiction to grant the same. The pursuit of equitable or injunctive relief shall not constitute a waiver on the part of Pc 7 to pursue any remedy for monetary damages through the arbitration described herein.
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li>
                      <h3 id="liability">
                        Release and Limitations of Liability
                      </h3>
                      <div class="t_c_content">
                        <ul>
                          <li class="pdBtm10">
                            Users shall access the Pc 7 Services provided on Pc 7 voluntarily and at their own risk. Pc 7 shall, under no circumstances be held responsible or liable on account of any loss or damage sustained (including but not limited to any accident, injury, death, loss of property) by Users or any other person or entity during the course of access to the Pc 7 Services (including participation in the Contest(s)) or as a result of acceptance of any prize.
                          </li>
                          <li class="pdBtm10">
                            By entering the contests and accessing the Pc 7 Services provided therein, Users hereby release from and agree to indemnify Pc 7, and/ or any of its directors, employees, partners, associates and licensors, from and against all liability, cost, loss or expense arising out their access to the Pc 7 Services including (but not limited to) personal injury and damage to property and whether direct, indirect, consequential, foreseeable, due to some negligent act or omission on their part, or otherwise.
                          </li>
                          <li class="pdBtm10">
                            Pc 7 accepts no liability, whether jointly or severally, for any errors or omissions, whether on behalf of itself or third parties in relation to the prizes.
                          </li>
                          <li class="pdBtm10">
                            Users shall be solely responsible for any consequences which may arise due to their access of Pc 7 Services by conducting an illegal act or due to non-conformity with these Terms and Conditions and other rules and regulations in relation to Pc 7 Services, including provision of incorrect address or other personal details. Users also undertake to indemnify Pc 7 and their respective officers, directors, employees and agents on the happening of such an event (including without limitation cost of attorney, legal charges etc.) on full indemnity basis for any loss/damage suffered by Pc 7 on account of such act on the part of the Users.
                          </li>
                          <li class="pdBtm10">
                            Users shall indemnify, defend, and hold Pc 7 harmless from any third party/entity/organization claims arising from or related to such User's engagement with the Pc 7 or participation in any Contest. In no event shall Pc 7 be liable to any User for acts or omissions arising out of or related to User's engagement with the Pc 7 or his/her participation in any Contest(s).
                          </li>
                          <li class="pdBtm10">
                            In consideration of Pc 7 allowing Users to access the Pc 7 Services, to the maximum extent permitted by law, the Users waive and release each and every right or claim, all actions, causes of actions (present or future) each of them has or may have against Pc 7, its respective agents, directors, officers, business associates, group companies, sponsors, employees, or representatives for all and any injuries, accidents, or mishaps (whether known or unknown) or (whether anticipated or unanticipated) arising out of the provision of Pc 7 Services or related to the Contests or the prizes of the Contests.
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li>
                      <h3 id="disclaimers">Disclaimers</h3>
                      <div class="t_c_content">
                        <ul>
                          <li class="pdBtm10">
                            To the extent permitted under law, neither Pc 7 nor its parent/holding company, subsidiaries, affiliates, directors, officers, professional advisors, employees shall be responsible for the deletion, the failure to store, the mis-delivery, or the untimely delivery of any information or material.
                          </li>
                          <li class="pdBtm10">
                            To the extent permitted under law, Pc 7 shall not be responsible for any harm resulting from downloading or accessing any information or material, the quality of servers, games, products, Pc 7 services or sites, cancellation of competition and prizes. Pc 7 disclaims any responsibility for, and if a User pays for access to one of Pc 7's Services the User will not be entitled to a refund as a result of, any inaccessibility that is caused by Pc 7's maintenance on the servers or the technology that underlies our sites, failures of Pc 7's service providers (including telecommunications, hosting, and power providers), computer viruses, natural disasters or other destruction or damage of our facilities, acts of nature, war, civil disturbance, or any other cause beyond our reasonable control. In addition, Pc 7 does not provide any warranty as to the content on the Pc 7(s). Pc 7(s) content is distributed on an "as is, as available" basis.
                          </li>
                          <li class="pdBtm10">
                            Any material accessed, downloaded or otherwise obtained through Pc 7 is done at the User's discretion, competence, acceptance and risk, and the User will be solely responsible for any potential damage to User's computer system or loss of data that results from a User's download of any such material.
                          </li>
                          <li class="pdBtm10">
                            Pc 7 shall make best endeavours to ensure that the Pc 7(s) is error-free and secure, however, neither Pc 7 nor any of its partners, licensors or associates makes any warranty that:
                            <ul class="pdTop10">
                              <li class="pdBtm10">
                                the Pc 7(s) will meet Users' requirements,
                              </li>
                              <li class="pdBtm10">
                                Pc 7(s) will be uninterrupted, timely, secure, or error free
                              </li>
                              <li class="pdBtm10">
                                the results that may be obtained from the use of Pc 7(s) will be accurate or reliable; and
                              </li>
                              <li class="pdBtm10">
                                the quality of any products, Pc 7 Services, information, or other material that Users purchase or obtain through Pc 7com(s) will meet Users' expectations.
                              </li>
                            </ul>
                          </li>
                          <li class="pdBtm10">
                            In case Pc 7 discovers any error, including any error in the determination of Winners or in the transfer of amounts to a User's account, Pc 7 reserves the right (exercisable at its discretion) to rectify the error in such manner as it deems fit, including through a set-off of the erroneous payment from amounts due to the User or deduction from the User's account of the amount of erroneous payment. In case of exercise of remedies in accordance with this clause, Pc 7 agrees to notify the User of the error and of the exercise of the remedy(ies) to rectify the same.
                          </li>
                          <li class="pdBtm10">
                            To the extent permitted under law, neither Pc 7 nor its partners, licensors or associates shall be liable for any direct, indirect, incidental, special, or consequential damages arising out of the use of or inability to use our sites, even if we have been advised of the possibility of such damages.
                          </li>
                          <li class="pdBtm10">
                            Any Pc 7 Services, events or Contest(s) being hosted or provided, or intended to be hosted on Pc 7 platform and requiring specific permission or authority from any statutory authority or any state or the central government, or the board of directors shall be deemed cancelled or terminated, if such permission or authority is either not obtained or denied either before or after the availability of the relevant Pc 7 Services, events or Contest(s) are hosted or provided.
                          </li>
                          <li class="pdBtm10">
                            To the extent permitted under law, in the event of suspension or closure of any Services, events or Contests, Users (including Participants) shall not be entitled to make any demands, claims, on any nature whatsoever.
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li>
                      <h3 id="ibc_Pc 7_contest">IBC Pc 7 Contest</h3>
                      <div class="t_c_content">
                        <ul>
                          <li class="pdBtm10">
                            Users participating in the IBC Pc 7 Contest hereby agree to be contacted by ICC Business Corporation FZ LLC (“IBC”) for promotion and marketing purposes. User information will only be shared if the user has joined the IBC Pc 7 contest. Users personal information shall only be used where General Data Protection Rules allows Pc 7 to do so. User may at any time withdraw their consent and request deletion of their personal information by writing to Pc 7 Helpdesk and once Pc 7 has received the user notification, Pc 7 will endeavour to delete or de-identify the user information and will no longer use users personal information unless Pc 7 has a legitimate basis for doing so by law.
                          </li>

                          <li class="pdBtm10">
                            The Participant(s) owning the Basket (s) with the highest aggregate score in a particular Session shall be declared the Winners. In the event of more than one participating Basket  having the same Basket  score, the Winner will be decided on the basis of points by earned by the captain of such competing Basket . If the participating Basket (s) have the same captain, then the participating Basket  with the vice -captain having higher score shall be declared a Winner. In the event of participating Basket (s) having the same captain and vice-captain, the Winner will be declared based on the Participant submitting their Basket  first.
                          </li>

                          <li class="pdBtm10">
                            All prizes are non-transferable and non-refundable. Prizes cannot be exchanged / redeemed for cash or kind. No cash claims can be made in lieu of prizes in kind.
                          </li>
                          <li class="pdBtm10">
                            ICC shall be solely responsible for distribution of prizes for all the IBC Pc 7 Contest winners. Pc 7 shall not be held responsible for any lapses on part of ICC to give the prizes.
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li>
                      <h3 id="miscellaneous">Miscellaneous</h3>
                      <div class="t_c_content">
                        <ul>
                          <li class="pdBtm10">
                            Pc 7 may be required under certain legislations, to notify User(s) of certain events. User(s) hereby acknowledge and consent that such notices will be effective upon Pc 7 posting them on the Pc 7 or delivering them to the User through the email address provided by the User at the time of registration. User(s) may update their email address by logging into their account on the Pc 7. If they do not provide Pc 7 with accurate information, Pc 7 cannot be held liable for failure to notify the User.
                          </li>
                          <li class="pdBtm10">
                            Pc 7 shall not be liable for any delay or failure to perform resulting from causes outside its reasonable control, including but not limited to any failure to perform due to unforeseen circumstances or cause beyond Pc 7's control such as acts of God, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, network infrastructure failures, strikes, or shortages of transportation facilities, fuel, energy, labor or materials or any cancellation of any cricket/football/basketball/kabaddi/volleyball/hockey/baseball/handball/American football Session to which a Contest relates. In such circumstances, Pc 7 shall also be entitled to cancel any related Contest(s) and to process an appropriate refund for all Participants.
                          </li>
                          <li class="pdBtm10">
                            Pc 7's failure to exercise or enforce any right or provision of these Terms and Conditions shall not constitute a waiver of such right or provision.
                          </li>
                          <li class="pdBtm10">
                            Users agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Pc 7 or these Terms must be filed within thirty (30) days of such claim or cause of action arising or be forever barred.
                          </li>
                          <li class="pdBtm10">
                            These Terms and Conditions, including all terms, conditions, and policies that are incorporated herein by reference, constitute the entire agreement between the User(s) and Sandhya Sports Media Private Limited and govern your use of the Pc 7, superseding any prior agreements that any User may have with Sandhya Sports Media Private Limited.
                          </li>
                          <li class="pdBtm10">
                            If any part of these Terms and Conditions is determined to be indefinite, invalid, or otherwise unenforceable, the rest of these Terms and Conditions shall continue in full force.
                          </li>
                          <li class="pdBtm10">
                            Pc 7 reserves the right to moderate, restrict or ban the use of the Pc 7, specifically to any User, or generally, in accordance with Pc 7's policy/policies from time to time, at its sole and absolute discretion and without any notice.
                          </li>
                          <li class="pdBtm10">
                            Pc 7 may, at its sole and absolute discretion, permanently close or temporarily suspend any Pc 7 Services (including any Contest(s)).
                          </li>
                          <li class="pdBtm10">
                            Pc 7 may from time to time conduct/organize, promotions/offers on the platform. Any two or more promotions cannot be clubbed together with any other promotions that are running simultaneously on the Pc 7 platform. Also, promotions/offers cannot be clubbed with Cash Bonus available with any user for any bundled contest joins via specially for you section on the home page.
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <h3 id="gr_mechanism">Grievance Redressal Mechanism</h3>
                      <div class="t_c_content">
                        <ul>
                          <li class="pdBtm10">
                            In case you have any complaints or grievance pertaining to (i) any User Content that you believe violates these Terms (other than an infringement of Intellectual Property Rights) for the reporting mechanism for such claims), (ii) your access to the Platform or (iii) any User Content which you believe is, prima facie, in the nature of any material which is obscene, defamatory towards the complainant or any person on whose behalf you are making the complaint, or is in the nature of impersonation in an electronic form, including artificially morphed images of such individual, please share the same with us by writing to:
                            <a href="mailto:support@Pc7.com">
                              support@Pc7.com
                            </a>
                          </li>
                          <li class="pdBtm10">
                            In your complaint or grievance, please include the following information:
                            <ul class="pdTop10">
                              <li class="pdBtm10">
                                Your name and contact details: name, address, contact number and email address;
                              </li>
                              <li class="pdBtm10">
                                Your relation to the subject matter of the complaint, i.e. complainant or person acting on behalf of an affected person;
                              </li>
                              <li class="pdBtm10">
                                The name and age of the person aggrieved or affected by the subject matter of the complaint, in case your are acting on behalf of such person and a statement, under penalty of perjury, that you are authorised to act on behalf of such person and to provide such person's personal information to us in relation to the complaint/grievance;
                              </li>
                              <li class="pdBtm10">
                                Description of the complaint or grievance with clear identification of the User Content in relation to which such complaint or grievance is made;
                              </li>
                              <li class="pdBtm10">
                                A statement that you believe, in good faith, that the User Content violates these Terms
                              </li>
                              <li class="pdBtm10">
                                A statement that the information provided in the complaint or grievance is accurate.
                              </li>
                            </ul>
                          </li>
                          <li class="pdBtm10">
                            The Company respects the Intellectual Property Rights of others. All names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary rights on the Platform belonging to any person (including User), entity or third party are recognized as proprietary to the respective owners. You are requested to send us a written notice/ intimation if you notice any act of infringement on the Platform, which must include the following information:
                            <ul class="pdTop10">
                              <li class="pdBtm10">
                                A clear identification of the copyrighted work allegedly infringed;
                              </li>
                              <li class="pdBtm10">
                                A clear identification of the allegedly infringing material on the Platform;
                              </li>
                              <li class="pdBtm10">
                                Your contact details: name, address, e-mail address and phone number;
                              </li>
                              <li class="pdBtm10">
                                A statement that you believe, in good faith, that the use of the copyrighted material allegedly infringed on the Platform is not authorized by your agent or the law;
                              </li>
                              <li class="pdBtm10">
                                A statement that the information provided in the notice is accurate, and under penalty of perjury, that the signatory is authorized to act on behalf of the owner of an exclusive copyright right that is allegedly infringed;
                              </li>
                              <li class="pdBtm10">
                                Your signature or a signature of your authorized agent.
                              </li>
                            </ul>
                            The aforesaid notices can be sent to the Company by email at
                            <a href="mailto:support@Pc7.com">
                              support@Pc7.com
                            </a>
                            .
                          </li>
                          <li class="pdBtm10">
                            On receiving such complaint, grievance or notice, the Company reserves the right to investigate and/or take such action as the Company may deem appropriate. The Company may reach out to you to seek further clarification or assistance with the investigation, or verify the statements made in your complaint, grievance or notice, and you acknowledge that your timely assistance with the investigation would facilitate the redressal of the same.
                          </li>
                          <li class="pdBtm10">
                            The name and title of the Grievance Redressal Officer is as follows:
                            <div>Name: Gaurav Bajaj</div>
                            <div>Email: support@Pc7.com</div>
                            <div>
                              Address: Sandhya Sports Media Private Limited, ONE BKC, Tower A, 12th & 14th Floor, Unit 1201 & 1202 and 1401 & 1402, Plot C-66, G Block, Bandra Kurla Complex, Bandra (East), Mumbai 400 051.
                            </div>
                            <div>
                              The Grievance Officer identified above pursuant to the provisions of applicable laws including but not limited to the Information Technology Act, 2000 and the Consumer Protection Act, 2019, and the Rules enacted under those laws.
                            </div>
                            <div>
                              The Company reserves the right to replace the Grievance Redressal Officer at its discretion through publication of the name and title of such replacement on the website, which replacement shall come into effect immediately upon publication.
                            </div>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <h3 id="inviteafriend">
                        Standard Terms and Conditions of "Invite Friends" Program.
                      </h3>
                      <div class="t_c_content">
                        <p class="pdBtm10">
                          The Pc 7 Invite Friends Program lets you invite friends (“<strong>Inviter</strong>”) to the Pc 7 app. In the event that you and your invited friend (“<strong>Invitee</strong>”) meet the criteria and complete all the steps specified in these terms, you will earn benefits as communicated to you on the app from 05:00:00 hours on 11 June, 2023 till IST 23:59:59 hours on 1 October, 2023, and your friend will receive discounts worth $10 ( “Invitee Discount”). To participate in the Program, please note our terms and conditions ("Terms") in this respect, as they govern your participation
                        </p>
                        <ul>
                          <li class="pdBtm10">
                            Eligibility – All users who: (i) have an account registered on the "Platform"; and (ii) are eligible to participate in the pay-to play game (as per the Pc 7's terms and conditions, accessible at https://fantasycricket.Pc 7.com/in/termsandconditions).
                          </li>
                          <li class="pdBtm10">
                            Participation in the program is voluntary. A user shall not register or operate more than one user account with Pc 7 and shall not participate in the Program with more than one user account with Pc 7.
                          </li>
                          <li class="pdBtm10">
                            The Program will be open for participation from Indian Standard Time 05:00:00 hours on 11 June, 2023 till IST 23:59:59 hours on 1 October, 2023
                          </li>
                          <li class="pdBtm10">
                            By participating in the Program, you agree to and accept these Terms
                          </li>
                          <li class="pdBtm10">
                            Rewards will be received by the Inviter only when the Invitee has joined a paid public contest. Simple registration or depositing of cash on Pc 7 by the Invitee will not result in any rewards for the Inviter
                          </li>
                          <li class="pdBtm10">
                            For the purpose of participation in the Program, you will be provided with a unique link or code, which can be shared by you with friends for the purpose of inviting such friends to and create an account on the Pc 7 Application. On receiving the link or code from the Inviter, the Invitee may either: (i) Click on the link, consequent to which such Invitee will be directed to a registration page and will be provided the option to download and install the Pc 7 Application on his/her device; and , register for a Pc 7 account via which he/she can enter the unique code shared by the Inviter where prompted in the Pc 7 Application.
                          </li>
                          <li class="pdBtm10">
                            The Invitee has to join a paid public contest before 1 October, 2023 for the Inviter to receive the benefits of the Invite Friends program.
                          </li>
                          <li class="pdBtm10">
                            The Inviter and the Invitee will be eligible to earn the benefits (as communicated on the app) subject to (amongst other terms specified in these Terms): (i) the Invitee not being an existing user of Pc 7; and (ii) the Inviter and Invitee being eligible to participate in the pay-to play contests on Pc 7; and (iii) the Invitee successfully registering for an account with Pc 7 through the unique link or by using the unique code shared by the Inviter; and (iv) the Inviter and Invitee downloading and installing the Pc 7 Application as available for the Inviter’s and Invitee’s respective mobile devices. For the purposes of these Terms, an 'existing user of Pc 7' shall mean a user who presently operates an account with Pc 7 or operated an account with Pc 7 at any point of time.
                          </li>
                          <li class="pdBtm10">
                            An Invitee who is an existing user of Pc 7 is not permitted to register a new account for the purpose of availing of the benefit as communicated on the app. Pc 7 will determine in its sole discretion whether an Invitee is an existing user of Pc 7 or not and take appropriate action.

                          </li>
                          <li class="pdBtm10">
                            The invited friend has to be at least 18 years old and not from one of the following states: Andhra Pradesh, Telangana, Sikkim, Odisha, Nagaland and Assam.
                          </li>
                          <li class="pdBtm10">
                            The verification process may require an Inviter/Invitee to submit personal information and documents identifying the Inviter/Invitee. The Inviter agrees to receive communication from Pc 7 and to allow Pc 7 to communicate with friends invited by you about the Inviter's participation in the Program. Any information collected in respect of the Inviter/Invitee as part of the Program or otherwise in the course of such person's use of the Platform shall be subject to Pc 7's Privacy Policy (available here: https://fantasycricket.Pc 7.com/in/privacypolicy for Indian users)
                          </li>
                          <li class="pdBtm10">
                            The verification of an Inviter/Invitee shall be completed at the time of first paid/cash contest join by the Inviter/Invitee or at the first time of adding cash to the Unutilised Account on the platform.
                          </li>
                          <li class="pdBtm10">
                            The Invitee will be eligible to receive a 75% discount up to a maximum of Rs. 100 (“Invitee Discount”). This Invitee Discount of 75% shall be valid for 21 days from the date of registration and will be valid on paid public contests above 1000 members before IST 23:59:59 hours on 1 October, 2023.
                          </li>
                          <li class="pdBtm10">
                            Inviter can receive one of the following Rewards which shall be communicated to the Inviter via the in-app communications:
                            a, Discounts: Inviter shall be eligible to receive a one-time maximum discount of Rs. 100, which shall be valid for a period of 21 days.
                            b, DreamCoins: DreamCoins credited for the Inviter will expire after 365 days
                            c, Cash Bonus: Cash Bonus credited for the Inviter will expire after 14 days
                            In addition to the above, terms and conditions (if any) as applicable on each of the rewards shall be communicated to the Inviter via the in-app communications.

                          </li>
                          <li class="pdBtm10">
                            Inviter Reward a) The credit of the Inviter rewards is contingent on the Invitee joining a paid public contest before IST 23:59:59 hours on 1 October, 2023. Subject to the provisions of above, the applicable Inviter rewards to the Inviter shall be credited to the Inviter's account within fifteen (15) days of the completion paid public contest joined by the Invitee.
                          </li>
                          <li class="pdBtm10">
                            The deposit of the rewards shall be subject to the Inviter’s/Invitee’s compliance with these Terms.
                          </li>
                          <li class="pdBtm10">
                            Pc 7 reserves the right to:
                            <ul>
                              <li>
                                withhold the deposit of the rewards; and/or
                              </li>
                              <li>
                                forfeit any deposited rewards to an Inviter/Invitee or any prizes/winnings earned by the participant by using such rewards; and/or
                              </li>
                              <li>
                                deactivate the accounts of the Inviter/Invitee, in the event that it determines or reasonably believes that such Inviter/Invitee has violated these Terms or the terms and conditions of the Pc 7 fantasy games.
                              </li>
                            </ul>
                          </li>
                          <li class="pdBtm10">
                            Pc 7 may, at its sole and absolute discretion, disqualify any Inviter/Invitee if such Inviter/Invitee engages in or it is found that such Inviter/Invitee has engaged in any illegal, unlawful or improper conduct (with regard to the Program or otherwise).
                          </li>
                          <li class="pdBtm10">
                            The decision of Pc 7 will be final and binding with regard to the Program, and the deposit of the Rewards and the Invitee Discount and no correspondence, objection, complaints, etc. will be entertained in this regard.
                          </li>
                          <li class="pdBtm10">
                            This Program cannot be clubbed with any other promotions/programs that are running simultaneously by Pc 7.
                          </li>
                          <li class="pdBtm10">
                            The Terms and Conditions, as applicable to the Pc 7's fantasy games and services, will apply to and govern the Program.
                          </li>
                          <li class="pdBtm10">
                            Pc 7 does not make any commitment, express or implied, to respond to any feedback, suggestion and, or, queries of the participants (Inviter/Invitee) of the Program.
                          </li>
                          <li class="pdBtm10">
                            Notwithstanding anything contained herein, the aggregate liability of Pc 7 to a participating Inviter/Invitee in relation to the Program for any reason whatsoever shall not exceed $10.
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* <!-- End #main --> */}

      {/* <!-- ======= Footer ======= --> */}
      {/* <Footer /> */}
      {/* <!-- End Footer --> */}

      {/* <a
        href="#"
        class="back-to-top d-flex align-items-center justify-content-center"
      >
        <i class="bi bi-arrow-up-short"></i>
      </a> */}
      {/* <div id="preloader"></div> */}
    </>
  );
};
