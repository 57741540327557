import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";

export const Home = () => {
  return (
    <>
      <header id="header" class="d-flex align-items-center header-transparent">
        <div class="container d-flex align-items-start justify-content-between">
          <div class="logo">        
            <Link to="/">
              <img src="img/logo.png" alt="" class="img-fluid" />
            </Link>
          </div>
          <nav id="navbar" class="navbar">
            <ul>
              <li>
                <a class="nav-link btn btn-primary scrollto" href="https://backend.Pc7.in/downloads">
                  {" "}
                  <i class="ri-download-fill me-2"></i> Download App
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>
      <section class="hero p-0 ">
        <div class="hero-main">
          <img
            src="img/hero-bg.png"
            class="img-fluid w-100 desktop-hero d-none d-sm-none d-md-block"
            alt=""
          />
          <img
            src="img/hero-mobile-banner.png"
            class="img-fluid w-100 desktop-hero d-block d-sm-block d-md-none"
            alt=""
          />
        </div>
      </section>
      <main id="main">
        <section id="counts" class="counts">
          <div class="container">
            <div class="row" data-aos="fade-up">
              <div class="col-lg-3 col-md-6">
                <div class="count-box">
                  <i class="bi bi-emoji-smile"></i>
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="232"
                    data-purecounter-duration="1"
                    class="purecounter  txt_gradient"
                  >
                    1.5 MILLION+
                  </span>
                  <p>Happy Clients</p>
                </div>
              </div>

              <div class="col-lg-3 col-md-6 mt-5 mt-md-0">
                <div class="count-box">
                  <i class="ri-currency-line"></i>
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="521"
                    data-purecounter-duration="1"
                    class="purecounter txt_gradient"
                  >
                    125 CRORES+
                  </span>
                  <p>Cash Winnings</p>
                </div>
              </div>

              <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
                <div class="count-box">
                  <i class="ri-focus-2-line"></i>
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="1463"
                    data-purecounter-duration="1"
                    class="purecounter  txt_gradient"
                  >
                    25 LAKHS+
                  </span>
                  <p>Contests Played</p>
                </div>
              </div>

              <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
                <div class="count-box">
                  <i class="ri-gift-line"></i>
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="15"
                    data-purecounter-duration="1"
                    class="purecounter  txt_gradient"
                  >
                    10 THOUSAND+
                  </span>
                  <p>Giveaway</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- ======= About Section ======= --> */}
        <section id="Downloading" class="Downloading pb-0">
          <div class="container">
            <div class="section-title aos-init aos-animate">
              <h2 className="text-white" >Downloading the</h2>
              <p className="txt_gradient" >Pc 7 App is Easy!</p>
            </div>

            <div class="row">
              <div class="col-md-4">
                <div class="howToinstallmain">
                  <div class="howToinstall-card">
                    <span class="number-icon">1</span>
                    <img src="img/install01.png" class="img-fluid" alt="" />
                  </div>
                  <h4>
                    Open www.pc7.world &amp; click on{" "}
                    <strong>“Download for Android”</strong> button
                  </h4>
                </div>
              </div>

              <div class="col-md-4">
                <div class="howToinstallmain">
                  <div class="howToinstall-card">
                    <span class="number-icon">2</span>
                    <img src="img/install03.png" class="img-fluid" alt="" />
                  </div>
                  <h4>
                    Open Pc7.com &amp; Wait for the apk to download
                    & click on<strong>“Open”</strong>
                  </h4>
                </div>
              </div>

              <div class="col-md-4">
                <div class="howToinstallmain">
                  <div class="howToinstall-card">
                    <span class="number-icon">3</span>
                    <img src="img/install02.png" class="img-fluid" alt="" />
                  </div>
                  <h4>
                    Click <strong>“Allow for this Source”</strong> when prompted
                    & then click on Install
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End About Section --> */}

        <section id="about" class="about">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="section-title aos-init aos-animate">
                  <h2>Enjoy Superfast Winnings with</h2>
                  <p className="txt_gradient"> Super-easy Gaming</p>
                </div>
              </div>

              <div
                class="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch"
                data-aos="fade-right"
              ></div>

              <div
                class="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5"
                data-aos="fade-left"
              >
                <div class="icon-box" data-aos="zoom-in" data-aos-delay="100">
                  <div class="icon">
                    <i class="">1</i>
                  </div>
                  <h4 class="title">
                    <a href="" className="txt_gradient">Select A Session </a>
                  </h4>
                  <p class="description">
                    Choose an upcoming Session that you want to play
                  </p>
                </div>
                <div class="icon-box" data-aos="zoom-in" data-aos-delay="200">
                  <div class="icon">
                    <i class="">2</i>
                  </div>
                  <h4 class="title">
                    <a href="" className="txt_gradient"> Create Basket </a>
                  </h4>
                  <p class="description">
                    Use your skills to pick the right Crypto
                  </p>
                </div>

                <div class="icon-box" data-aos="zoom-in" data-aos-delay="300">
                  <div class="icon">
                    <i class="">3</i>
                  </div>
                  <h4 class="title">
                    <a href="" className="txt_gradient">Join Contest</a>
                  </h4>
                  <p class="description">
                    Choose between different contests and win money
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="welcome-sec pb-0">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-md-6 order-md-2">
                <div class="content">
                  <div class="heading">
                    <h2>
                      A Super <span class="head-text">Welcome Bonus</span>
                    </h2>
                    <h6>DOWNLOAD &amp; REGISTER TO GET</h6>
                    <h5>
                      <span>$10</span> Sign up bonus
                    </h5>
                  </div>
                  <div class="welcome_btn mt-4 mb-3">
                    <a href="#">
                      <img
                        src="img/andrid-btn.png"
                        class="img-fluid w-100"
                        alt=""
                      />
                    </a>
                    <a href="#">
                      <img
                        src="img/apple-btn.png"
                        class="img-fluid w-100"
                        alt=""
                      />
                    </a>
                  </div>
                  <p>
                    <small>
                      *This game involves an element of financial risk and may
                      be addictive. please play responsibly and at your own
                      risk.
                    </small>
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="welcome_img">
                  <img src="img/about-img.png" class="img-fluid" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>



        <section class="inner-page counts">
          <div class="container">
            <div class="content">
              <div class="TermsCondtns">
                  <div class="TermsCondtnsBox">
                   

                  <div class="termsContent ftFx13 terms_condition_list mt-5 pt-5 "> 

<div class="section-title aos-init aos-animate"><h2>Why PC7</h2><p class="txt_gradient">  Why Choose PC7</p></div>
<div className="row g-4" >
  <div className="col-md-6 col-lg-4" >
    <div className="count-box h-100" >

   
  <p>
  <h3> <small> <small>Immersive Gameplay</small> </small></h3>
    </p>
    <div class="t_c_content  mt-3   mb-3   ">  
      <p>
      Experience the excitement of crypto trading in a dynamic and engaging environment.

      </p>  
    </div> 
    </div>
  </div>
  
  <div className="col-md-6 col-lg-4" >
  <div className="count-box  h-100" >
  <h3> <small> <small>Strategy Matters:</small> </small></h3> 
    <div class="t_c_content  mt-3   mb-3   ">  
        <p>
        Test your trading strategies without risking real funds. Sharpen your skills and learn from market dynamics
        </p>  
        </div> 
        </div>

  </div>

  
  <div className="col-md-6 col-lg-4" >
  <div className="count-box  h-100" >
  <h3> <small> <small>Compete and Win  :</small> </small></h3>  
    <div class="t_c_content  mt-3   mb-3   ">  
        <p>
        Join contests and compete against traders worldwide. Showcase your trading prowess and win exciting prizes.
        </p>  
      </div> 
      </div>
  </div>

  
  <div className="col-md-6" >
  <div className="count-box  h-100" >
  <h3> <small> <small> 
      Real-Time Data:</small> </small></h3>   
      
    <div class="t_c_content  mt-3   mb-3   ">  
        <p>
        Stay updated with real-time market data from CoinMarketCap, ensuring a lifelike trading experience.  </p>  
      </div> 
      </div>
  </div>
  

  
  <div className="col-md-6" >
  <div className="count-box  h-100" >
    
  <h3> <small> <small> Community Interaction: </small> </small></h3>  
    <div class="t_c_content  mt-3   mb-3   ">  
          <p>
          Connect with like-minded traders, share insights, and learn from each other's experiences.

          </p>  
        </div> 
        </div>
  </div> 

</div>





 



 



 
 



 







 





</div>

                  


                  
                  </div>
                </div>
            </div>
          </div>
        </section>



        {/* <!-- ======= F.A.Q Section ======= --> */}
        {/* <section id="faq" class="faq section-bg">
          <div class="container">
            <div class="section-title" data-aos="fade-up">
              <h2>F.A.Q</h2>
              <p className="txt_gradient" >Frequently Asked Questions</p>
            </div>

            <div class="faq-list">
              <ul>
                <li data-aos="fade-up">
                  <a
                    data-bs-toggle="collapse"
                    class="collapse"
                    data-bs-target="#faq-list-1"
                  >
                    What is Fantasy Games?
                    <i class="bx bx-chevron-down icon-show"></i>
                    <i class="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-1"
                    class="collapse show"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      Fantasy Games is one of the biggest Fantasy Games
                      tournaments played by cricket lovers during the IPL
                      season.
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="100">
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-2"
                    class="collapsed"
                  >
                    How To Play Fantasy Games and Win Cash Daily?
                    <i class="bx bx-chevron-down icon-show"></i>
                    <i class="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-2"
                    class="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      For playing Fantasy Games & fantasy football on Pc 7 , you need to go through the below steps:
                      <br /> -Download the Pc 7 app & install it on
                      your mobile.
                      <br /> -Make a registration via your mobile number or
                      email id.
                      <br />
                      -Select a sport.
                      <br />
                      -Choose a contest from that picked sport.
                      <br /> -Pick real Crypto and make your best Basket .
                      <br />
                      -You will be a winner if the total point of your Basket  is
                      highest than other Basket s.
                      <br /> -Thereafter, you will be getting a real cash prize.
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="200">
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-3"
                    class="collapsed"
                  >
                    What is IPL Fantasy League?
                    <i class="bx bx-chevron-down icon-show"></i>
                    <i class="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-3"
                    class="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      IPL Fantasy Games is one of the biggest Fantasy Games
                      tournaments played by cricket lovers during the IPL
                      season.
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="300">
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-4"
                    class="collapsed"
                  >
                    How to Play IPL Fantasy?
                    <i class="bx bx-chevron-down icon-show"></i>
                    <i class="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-4"
                    class="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      For playing Fantasy Games & fantasy football on Pc 7, you need to go through the below steps:
                      <br />
                      -Download the Pc 7 app & install it on your
                      mobile.
                      <br />
                      -Make a registration via your mobile number or email id.
                      <br />
                      -Select a sport.
                      <br />
                      -Choose a contest from that picked sport.
                      <br />
                      -Pick real Crypto and make your best Basket .
                      <br />
                      -You will be a winner if the total point of your Basket  is
                      highest than other Basket s.
                      <br />
                      -Thereafter, you will be getting a real cash prize.
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="400">
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-5"
                    class="collapsed"
                  >
                    Beyond Cricket - Fantasy Football And Much More
                    <i class="bx bx-chevron-down icon-show"></i>
                    <i class="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-5"
                    class="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      Pc 7 is the most trusted and secured online
                      fantasy sports gaming platform that strictly follows
                      fantasy sports and gaming rules & regulations and runs OFS
                      contests based on fair game policy. Security is our
                      priority and we are very much committed to providing
                      secure services by safeguarding user’s personal
                      information.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section> */}
        {/* <!-- End F.A.Q Section --> */}

        {/* <!-- ======= Contact Section ======= --> */}
        <section id="contact" class="contact">
          <div class="container">
            <div class="section-title" data-aos="fade-up">
              <h2>Contact</h2>
              <p className="txt_gradient" >Contact Us</p>
            </div>

            <div class="row align-items-start">
              <div class="col-lg-12">
                <div class="info row align-items-start">
                  <div class="email  col-md-4 mt-md-0">
                    <i class="bi bi-envelope"></i>
                    <h4>Email:</h4>
                    <p>support@Pc7.com</p>
                  </div>

                  {/* <div class="phone  col-md-4  mt-md-0">
                    <i class="bi bi-phone"></i>
                    <h4>Call:</h4>
                    <p> <a href="tel:1234567890" >  +91 123-456-7890</a> </p>
                  </div> */}
                  <div class="address col-md-4">
                    <i class="bi bi-geo-alt"></i>
                    <h4>Location:</h4>
                    
                    <p>Noida, Uttar Pradesh  </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
      <a
        href="#"
        class="back-to-top d-flex align-items-center justify-content-center"
      >
        <i class="bi bi-arrow-up-short"></i>
      </a>
    </>
  );
};
