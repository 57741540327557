import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";

export const HowToPlay = () => {
  return (
    <>
      {/* <!-- ======= Header ======= --> */}
      <header id="header" class="d-flex align-items-center header-transparent ">
        <div class="container d-flex align-items-start justify-content-between">
          <div class="logo">
            {/* <!-- <h1><a href="index.html"><span>Bootslander</span></a></h1> -->
        <!-- Uncomment below if you prefer to use an image logo --> */}
            <Link to="/">
              <img src="img/logo.png" alt="" class="img-fluid" />
            </Link>
          </div>

          {/* <nav id="navbar" class="navbar">
            <ul>
              <li>
                <a class="nav-link btn btn-primary scrollto" href="https://backend.Pc7.in/downloads">
                  {" "}
                  <i class="ri-download-fill me-2"></i> Download App
                </a>
              </li>
            </ul>
          </nav> */}

          {/* <!-- .navbar --> */}
        </div>
      </header>
      {/* <!-- End Header --> */}

      <section class="inner_hero_sec">
        <div class="heading">
          <h1>How it Works  </h1>
        </div>
      </section>

      <main id="main">
        <section class="breadcrumbs">
          <div class="container">
            <div class="d-flex justify-content-between align-items-center">
              <ol>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>How it Works</li>
              </ol>
            </div>
          </div>
        </section>
        {/* <!-- End Breadcrumbs Section --> */}

        <section class="inner-page counts">
          <div class="container">
            <div class="content">
              <div class="TermsCondtns">
                  <div class="TermsCondtnsBox">
                    

                  <div class="termsContent ftFx13 terms_condition_list"> 
                      <p>
                      Step <b>1) Choose Your Trend:</b>
                      </p>
                      <div class="t_c_content  mt-3   mb-4 mb-md-5 ">  
                        <p>
                        Before you begin your trading journey, predict whether the market will be bullish or bearish. Your decision will influence your strategy and the coins you select for your portfolio.
                        </p>  
                      </div> 
                      <p>
                      Step <b>2) Create Your Portfolio :</b>
                      </p>
                      <div class="t_c_content  mt-3   mb-4 mb-md-5 "> 
                        <p>
                        Select seven coins from the top 20 ranked coins on CoinMarketCap. Craft your portfolio strategically, considering factors like market trends, coin performance, and risk management.
                        </p>  
                      </div> 
                      <p>
                      Step <b>3) Join Contests:</b>
                      </p>
                      <div class="t_c_content  mt-3   mb-4 mb-md-5 "> 

                        <p>
                        Compete against fellow traders in thrilling contests where you'll put your trading skills against others. Accumulate fantasy points based on the performance of your portfolio compared to real-time market data.
                          </p>  
 
                      </div> 
                      <p>
                      Step <b>4) Win Big: </b>
                      </p>
                      <div class="t_c_content  mt-3   mb-4 mb-md-5 ">  
                        <p>
                        Rack up fantasy points and climb the leaderboard to claim victory. Prizes await those who demonstrate the best trading strategies and earn the highest fantasy points.</p>

                      </div>

                  </div> 
                  </div>
                </div>
            </div>
          </div>
        </section>
      </main>
      {/* <!-- End #main --> */}

      {/* <!-- ======= Footer ======= --> */}
      <Footer />
      {/* <!-- End Footer --> */}

      <a
        href="#"
        class="back-to-top d-flex align-items-center justify-content-center"
      >
        <i class="bi bi-arrow-up-short"></i>
      </a>
      {/* <div id="preloader"></div> */}
    </>
  );
};
