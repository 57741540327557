// import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";

export const HowToPlayMobile = () => {
  return (
    <>
      {/* <!-- ======= Header ======= --> */}
      {/* <header id="header" class="d-flex align-items-center header-transparent ">
        <div class="container d-flex align-items-start justify-content-between">
          <div class="logo"> 
            <Link to="/">
              <img src="img/logo.png" alt="" class="img-fluid" />
            </Link>
          </div>

          <nav id="navbar" class="navbar">
            <ul>
              <li>
                <a class="nav-link btn btn-primary scrollto" href="https://backend.Pc7.in/downloads">
                  {" "}
                  <i class="ri-download-fill me-2"></i> Download App
                </a>
              </li>
            </ul>
          </nav>
 
        </div>
      </header> */}
      {/* <!-- End Header --> */}

      <section class="inner_hero_sec">
        <div class="heading">
          <h1>How To Play</h1>
        </div>
      </section>

      <main id="main">
        {/* <section class="breadcrumbs">
          <div class="container">
            <div class="d-flex justify-content-between align-items-center">
              <ol>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>How To Play</li>
              </ol>
            </div>
          </div>
        </section> */}
        {/* <!-- End Breadcrumbs Section --> */}

        <section class="inner-page">
          <div class="container">
            <div class="content">
              The Pc 7 users are enforced to follow certain Terms &
              Conditions associated with the usage of Pc 7. It is
              mandatory for the users to follow the terms and conditions of
              Pc 7 while using the services provided by Pc 7:
            </div>
          </div>
        </section>
      </main>
      {/* <!-- End #main --> */}

      {/* <!-- ======= Footer ======= --> */}
      {/* <Footer /> */}
      {/* <!-- End Footer --> */}

      {/* <a
        href="#"
        class="back-to-top d-flex align-items-center justify-content-center"
      >
        <i class="bi bi-arrow-up-short"></i>
      </a> */}
      {/* <div id="preloader"></div> */}
    </>
  );
};
