// import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

export const AboutMobile = () => {
  return (
    <>   

      <section class="inner_hero_sec">
        <div class="heading">
          <h1>About Us</h1>
        </div>
      </section>

      <main id="main">
       
        <section class="inner-page">
          <div class="container">
          <div class="content">
              <div>
                <h3 className="mb-4" >Welcome to "Pc7" </h3>
                <p class="text-justify">
                  <strong  > At Pc7,</strong>
                  where fantasy meets crypto trading! Dive into the world of virtual trading, where you have the power to predict market trends and create your own winning portfolio. PC7 is not just a game; it's an immersive experience that combines the excitement of fantasy sports with the thrill of cryptocurrency trading.

                </p>
                <br />
                <h3>Contact us</h3>
                <p class="text-justify">
                  Have questions, suggestions, or just want to share your epic victories? Reach out to us at <a className="text-decoration-underline" href="mailto:support@Pc7.com" >support@Pc7.com</a>. Your feedback fuels our quest to make Pc7 an unforgettable gaming experience.
                </p>
                <p>
                <br/>
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>    
    </>
  );
};
